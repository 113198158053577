import BaseService from "../Base";
import ApiUrls from "../ApiUrl";
import { Bussiness } from "types/admin/bussinessList";
import { ParamType } from "types/Others";

export default class BussinessListService extends BaseService {
  async getBussinessList(params: ParamType) {
    try {
      const response = await this.post(`${ApiUrls.user}/list`, params);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async createBussinessList(params: Bussiness.FormType) {
    try {
      const response = await this.post(`${ApiUrls.user}/create`, params);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async updateBussinessList(params: Bussiness.UpdateFormType) {
    try {
      const response = await this.post(`${ApiUrls.user}/update`, params);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteBussinessList(params: Bussiness.deleteParamType) {
    try {
      const response = await this.post(`${ApiUrls.user}/delete`, params);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
