import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";
import { bussinessListService } from "services";
import { ActionPayload, MetaPagination, ParamType } from "types/Others";
import { Bussiness } from "types/admin/bussinessList";

export const getBussinessListFromStore = createAsyncThunk(
  "auth/getBussinessListFromStore",
  async (param: ParamType) => {
    return await bussinessListService.getBussinessList(param);
  }
);

export const getDetailBussinessListFromStore = createAsyncThunk(
  "auth/getDetailBussinessListFromStore",
  async (param: ParamType = {}) => {
    return await bussinessListService.getBussinessList(param);
  }
);

export const createBussinessListFromStore = createAsyncThunk(
  "auth/createBussinessListFromStore",
  async (param: Bussiness.FormType) => {
    return await bussinessListService.createBussinessList(param);
  }
);

export const updateBussinessFromStore = createAsyncThunk(
  "auth/updateBussinessFromStore",
  async (param: Bussiness.UpdateFormType) => {
    return await bussinessListService.updateBussinessList(param);
  }
);

export const deleteBussinessListFromStore = createAsyncThunk(
  "auth/deleteBussinessListFromStore",
  async ({ delPayload, getPayload }: any, { dispatch }) => {
    const res = await bussinessListService.deleteBussinessList(delPayload);
    await dispatch(getBussinessListFromStore(getPayload));
    return { message: res?.message };
  }
);

type BussinessListState = {
  bussinessList: any[];
  isLoading: boolean;
  isLoaded: boolean;
  meta: MetaPagination;
  total: number;
  userDetails: any;
  search: string;
};

const initialState: BussinessListState = {
  bussinessList: [],
  isLoading: true,
  isLoaded: false,
  meta: {},
  total: 0,
  userDetails: null,
  search: ""
};

export const bussinessListSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetLoader: (state, action?) => {
      state.isLoaded = false;
    },
    setBLSearchState: (state, action: PayloadAction<ActionPayload>) => {
      state.search = action.payload?.data;
    },
    resetBussinessList: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // GET ACTION
      .addCase(getBussinessListFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(getBussinessListFromStore.fulfilled, (state, action) => {
        const { data, meta, total } = action.payload;
        state.bussinessList = data;
        state.meta = meta;
        state.total = total;
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(getBussinessListFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // DETAIL ACTION

      .addCase(getDetailBussinessListFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(getDetailBussinessListFromStore.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.userDetails = data[0] || null;
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(getDetailBussinessListFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // CREATE ACTION
      .addCase(createBussinessListFromStore.fulfilled, (state, action) => {
        let { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(createBussinessListFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(createBussinessListFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // UPDATE ACTION
      .addCase(updateBussinessFromStore.fulfilled, (state, action) => {
        let { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(updateBussinessFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(updateBussinessFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // DELETE ACTION

      .addCase(deleteBussinessListFromStore.fulfilled, (state, action) => {
        let { message } = action.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(deleteBussinessListFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(deleteBussinessListFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      });
  },
});

export const { resetBussinessList, resetLoader, setBLSearchState } = bussinessListSlice.actions;

export default bussinessListSlice.reducer;
export const selectBussinessList = (state: RootState) =>
  state.bussinessListState;
export const selectBLSearch = (state: RootState) =>
  state.bussinessListState.search;  
