import Label from "components/label";
import { SelectOptions } from "types/Others";

type SelectProps = {
  ref?: any;
  label?: string;
  required?: boolean;
  value?: string | number;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  containerStyle?: any;
  inputStyle?: any;
  error?: any;
  title?: string;
  id?: string;
  options: SelectOptions[];
  onChange?: (event: any) => void;
};
function Select({
  ref = null,
  label = "",
  required = false,
  disabled = false,
  placeholder = "",
  name = "",
  value = "",
  containerStyle = null,
  inputStyle = null,
  error = null,
  options = [],
  title = "title",
  id = "value",
  onChange = () => null,
}: SelectProps) {
  return (
    <div className={`form-floating ${containerStyle || ""}`}>
      <select
        ref={ref}
        className={`form-select ${inputStyle || ""}`}
        value={value}
        onChange={onChange}
        id={`id_${name}`}
        name={name}
        disabled={disabled}
      >
        {!!placeholder && <option value="">{placeholder}</option>}
        {options?.map((option: any, index: number) => (
          <option key={`${index}_${option[title]}`} value={option[id]}>
            {option[title]}
          </option>
        ))}
      </select>
      <Label title={label} required={required} />
      {!!error && <span className="inputError">{error}</span>}
    </div>
  );
}

export default Select;
