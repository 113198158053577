import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { specialistService } from "services";
import { MetaPagination } from "types/Others";
import { Master } from "types/common/master";
import { toastSuccess } from "helpers/toastHelper";
import { isObjectBlank } from "helpers/common";

export const getSpecialistFromStore = createAsyncThunk(
  "auth/getSpecialistFromStore",
  async (param: Master.BodyParamType, { dispatch }) => {
    if(isObjectBlank(param)) param = {
      range: { 
        page: 1,
        pageSize: 100
      }
    }
    return await specialistService.getSpecialist(param);
  }
);
export const createSpecialistFromStore = createAsyncThunk(
  "auth/createSpecialistFromStore",
  async ({ payload, getPayload }: any, { dispatch }) => {
    const res = await specialistService.createSpecialist(payload);
    await dispatch(getSpecialistFromStore(getPayload));
    return res;
  }
);

export const updateSpecialistFromStore = createAsyncThunk(
  "auth/updateSpecialistFromStore",
  async ({ payload, getPayload }: any, { dispatch }) => {
    const res = await specialistService.updateSpecialist(payload);
    await dispatch(getSpecialistFromStore(getPayload));
    return res;
  }
);

export const deleteSpecialistFromStore = createAsyncThunk(
  "auth/deleteSpecialistFromStore",
  async ({ delPayload, getPayload }: any, { dispatch }) => {
    const res = await specialistService.deleteSpecialist(delPayload);
    await dispatch(getSpecialistFromStore(getPayload));
    return res;
  }
);

type SpecialistsInitialState = {
  specialists: any[];
  isLoading: boolean;
  isLoaded: boolean;
  meta: MetaPagination;
  total: number;
};

const initialState: SpecialistsInitialState = {
  specialists: [],
  isLoading: true,
  isLoaded: false,
  meta: {},
  total: 0,
};

export const specialistSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetSpecialistLoader: (state, action?) => {
      state.isLoaded = false;
    },
    resetSpecialist: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // GET ACTION
      .addCase(getSpecialistFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(getSpecialistFromStore.fulfilled, (state, action) => {
        const { data, meta, total } = action?.payload;
        state.specialists = data;
        state.meta = meta;
        state.total = total;
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(getSpecialistFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // CREATE ACTION
      .addCase(createSpecialistFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(createSpecialistFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(createSpecialistFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // UPDATE ACTION
      .addCase(updateSpecialistFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(updateSpecialistFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(updateSpecialistFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // DELETE ACTION
      .addCase(deleteSpecialistFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(deleteSpecialistFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(deleteSpecialistFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      });
  },
});

export const { resetSpecialistLoader, resetSpecialist } = specialistSlice.actions;

export default specialistSlice.reducer;
export const selectSpecialist = (state: RootState) => state.specialistState;
export const selectSpecialists = (state: RootState) => state.specialistState.specialists;
export const selectSpecialistTotal = (state: RootState) => state.specialistState.total;
export const selectSpecialistLoading = (state: RootState) => state.specialistState.isLoading;
