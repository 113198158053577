import { Loadable, MainLayout } from "components";
import Notifications from "pages/common/notifications";
import PaymentRedirect from "pages/user/manage-content/create-content/payment-redirect";
import { lazy } from "react";
// import path from "path";

const Error404 = Loadable(lazy(() => import("pages/auth/error")));

// ==============================|| User or Client ROUTING ||============================== //
// const Dashboard = Loadable(lazy(() => import("pages/user/dashboard")));
const Dashboard = Loadable(lazy(() => import("pages/common/dashboard")));
// const DeepRoute = Loadable(lazy(()=>import("pages/user/CreateCampaignNew")))
const Publish = Loadable(lazy(() => import("pages/user/publishingDetails")));

const CampaignForm = Loadable(
  lazy(() => import("pages/user/campaigns/new-campaign"))
);
const Campaigns = Loadable(lazy(() => import("pages/user/campaigns")));
const Analytics = Loadable(lazy(() => import("pages/user/analytics")));
const Profile = Loadable(lazy(() => import("pages/user/profile")));
const ManageContent = Loadable(lazy(() => import("pages/user/manage-content")))
const CreateContent = Loadable(lazy(()=> import("pages/user/manage-content/create-content"))) 

// ==============================|| Admin ROUTING ||============================== //

const BusinessList = Loadable(lazy(() => import("pages/admin/business-list")));
const CreateBusiness = Loadable(
  lazy(() => import("pages/admin/business-list/new-business"))
);
const Master = Loadable(lazy(() => import("pages/common/master")));
const SubscriptionPackages = Loadable(
  lazy(() => import("pages/admin/subscription-packages"))
);
const UserAccessTier = Loadable(
  lazy(() => import("pages/admin/user-access-tier"))
);
const Users = Loadable(lazy(() => import("pages/admin/users")));
const EmotionalContext = Loadable(
  lazy(() => import("pages/admin/admin-master/emotion-context"))
);
const Tone = Loadable(lazy(() => import("pages/admin/admin-master/tone")));
const Language = Loadable(
  lazy(() => import("pages/admin/admin-master/language"))
);
const Manner = Loadable(lazy(() => import("pages/admin/admin-master/manner")));
// const AdminDashboard = Loadable(lazy(() => import("pages/admin/admin-dashboard")));
const BrandName = Loadable(
  lazy(() => import("pages/user/client-master/brand-name"))
);
const HighestQualification = Loadable(
  lazy(() => import("pages/user/client-master/highest-qualification"))
);
const Division = Loadable(
  lazy(() => import("pages/user/client-master/division"))
);
const InterestArea = Loadable(
  lazy(() => import("pages/user/client-master/interest-area"))
);
const Specialization = Loadable(
  lazy(() => import("pages/user/client-master/specialization"))
);
const Specialty = Loadable(
  lazy(() => import("pages/user/client-master/specialty"))
);
const SubSpecialization = Loadable(
  lazy(() => import("pages/user/client-master/sub-specialization"))
);
const PressRelease = Loadable(lazy(() => import("pages/admin/press-release")));
const PressReleaseDetail = Loadable(
  lazy(() => import("pages/admin/press-release/press-release-detail"))
);
const CampaignsDetail = Loadable(
  lazy(() => import("pages/user/campaigns/campaign-detail"))
)
const ClientList = Loadable(lazy(() => import("pages/user/clients")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "dashboard",
      element: <Dashboard />,
    },
    {
      path: "campaigns",
      element: <Campaigns />,
    },
    {
      path: "campaign/update",
      element: <CampaignForm />,
    },
    {
      path: "campaign/create",
      element: <CampaignForm />,
    },
    {
      path: "campaigns/view",
      element: <CampaignsDetail />,
    },
    {
      path: "publish",
      element: <Publish />,
    },
    {
      path: "real-time-analysis",
      element: <Analytics />,
    },
    {
      path: "account-settings",
      element: <Profile />,
    },
    {
      path: "users",
      element: <Users />,
    },
    {
      path: "users/create",
      // element: <UserForm />,
    },
    {
      path: "business-list",
      element: <BusinessList />,
    },
    {
      path: "business-list/create",
      element: <CreateBusiness />,
    },
    {
      path: "master",
      element: <Master />,
    },
    {
      path: "master/tone",
      element: <Tone />,
    },
    {
      path: "master/language",
      element: <Language />,
    },
    {
      path: "master/manner",
      element: <Manner />,
    },
    {
      path: "subscription-packages",
      element: <SubscriptionPackages />,
    },
    {
      path: "user-access-tier",
      element: <UserAccessTier />,
    },
    // {
    //   path: "admin-dashboard",
    //   element: <AdminDashboard />,
    // },
    
    //  Deep route
    // {
    //   path: "deep-campaign",
    //   element: <DeepRoute/>
    // },
    {
      path: "manage-content",
      element: <ManageContent/>
    },
    {
      path: "manage-content/add-new", 
      element: < CreateContent/>
    },
    {
      path: "manage-content/payment-redirect", 
      element: < PaymentRedirect/>
    },
    {
      path: "press-releases",
      element: <PressRelease />,
    },
    {
      path: "press-releases/view/:id",
      element: <PressReleaseDetail />,
    },
    {
      path: "master/emotion-context",
      element: <EmotionalContext />,
    },
    {
      path: "master/brand-name",
      element: <BrandName />,
    },
    {
      path: "master/division",
      element: <Division />,
    },
    {
      path: "master/highest-qualification",
      element: <HighestQualification />,
    },
    {
      path: "master/interest-area",
      element: <InterestArea />,
    },
    {
      path: "master/specialization",
      element: <Specialization />,
    },
    {
      path: "master/specialty",
      element: <Specialty />,
    },
    {
      path: "master/sub-specialization",
      element: <SubSpecialization />,
    },
    {
      path: "notifications",
      element: <Notifications />,
    },
    {
      path: "clients",
      element: <ClientList />,
    },
    {
      path: "*",
      element: <Error404 />,
    },
  ],
};

export default MainRoutes;
