import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";
import { campaignService } from "services";
import { ActionPayload, MetaPagination, ParamType, StatusTypes } from "types/Others";
import { Bussiness } from "types/admin/bussinessList";
import { CampaignProps } from "types/users/Campaign";

export const getCampaignNamesFromStore = createAsyncThunk(
  "auth/getCampaignNamesFromStore",
  async (param: ParamType) => {
    return await campaignService.getCampaignNames(param);
  }
);

export const getCampaignsFromStore = createAsyncThunk(
  "auth/getCampaignsFromStore",
  async (param: Bussiness.ParamType) => {
    return await campaignService.getCampaigns(param);
  }
);

export const createCampaignFromStore = createAsyncThunk(
  "auth/createCampaignFromStore",
  async (param: CampaignProps.NewSubCampaignProps, { dispatch }) => {
    const cr = !!param?.subCampaignId ? await campaignService.updateCampaign(param) : await campaignService.createCampaign(param);
    await dispatch(getCampaignNamesFromStore({}));
    const res = await dispatch(getCampaignsFromStore({}));
    return { message: cr?.data?.message, res };
  }
);

export const getUserPromotionFromStore = createAsyncThunk(
  "auth/getUserPromotionFromStore",
  async (param: ParamType) => {
    return await campaignService.getUserPromotion(param);
  }
);

type CampaignState = {
  campaignNames: any[];
  campaignList: any[];
  userPromotions: any[];
  isLoading: boolean;
  isLoaded: boolean;
  meta: MetaPagination;
  total: number;
  campaignNamesMeta: MetaPagination;
  campaignNamesTotal: number;
  createLoading: boolean;
  search: string;
  campaignStatusType: StatusTypes;
};

const initialState: CampaignState = {
  campaignNames: [],
  campaignList: [],
  userPromotions: [],
  isLoading: true,
  isLoaded: false,
  meta: {},
  total: 0,
  campaignNamesMeta: {},
  campaignNamesTotal: 0,
  createLoading: false,
  search: "",
  campaignStatusType: ""
};

export const campaignSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetLoader: (state, action?) => {
      state.isLoaded = false;
    },
    setCreateLoadingState: (state, action: PayloadAction<ActionPayload>) => {
      state.createLoading = action.payload?.loading || false;
    },
    setCampaignSearchState: (state, action: PayloadAction<ActionPayload>) => {
      state.search = action.payload?.data;
    },
    setCampaignStatusState: (state, action: PayloadAction<ActionPayload>) => {
      state.campaignStatusType = action.payload?.data;
    },  
    resetCampaignList: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCampaignNamesFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = false;
        // state.campaignNamesTotal = 0;
      }).addCase(getCampaignNamesFromStore.fulfilled, (state, action) => {
        const { data, meta, total } = action.payload;
        state.campaignNames = data;
        state.isLoading = false;
        state.isLoaded = true;
        state.campaignNamesMeta = meta;
        state.campaignNamesTotal = total;
      }).addCase(getCampaignNamesFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.campaignNames = [];
        state.campaignNamesTotal = 0;
      })

    builder
      .addCase(getCampaignsFromStore.pending, (state, action) => {
        state.campaignList = [];
        state.total = 0;
        state.isLoading = true;
        state.isLoaded = false;
      }).addCase(getCampaignsFromStore.fulfilled, (state, action) => {
        const { data, meta, total } = action.payload;
        state.campaignList = data;
        state.meta = meta;
        state.total = total;
        state.isLoading = false;
        state.isLoaded = true;
      }).addCase(getCampaignsFromStore.rejected, (state, action) => {
        state.total = 0;
        state.isLoading = false;
        state.isLoaded = true;
        state.campaignList = [];
      })  

    builder
      .addCase(createCampaignFromStore.pending, (state, action) => {
        state.campaignList = [];
        state.total = 0;
        state.isLoading = true;
        state.isLoaded = true;
      }).addCase(createCampaignFromStore.fulfilled, (state, action) => {
        const { message, res } = action?.payload;
        !!message && toastSuccess(message);
        state.campaignList = res?.payload?.data;
        state.meta = res?.payload?.data?.meta;
        state.total = res?.payload?.data?.total;
        state.isLoading = false;
        state.isLoaded = true;
      }).addCase(createCampaignFromStore.rejected, (state, action) => {
        state.total = 0;
        state.isLoading = false;
        state.isLoaded = true;
        state.campaignList = [];
      })  
      builder
      .addCase(getUserPromotionFromStore.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.userPromotions = data;
        state.isLoading = false;
      })
  },
});

export const { resetCampaignList, resetLoader, setCreateLoadingState, setCampaignSearchState, setCampaignStatusState } = campaignSlice.actions;

export default campaignSlice.reducer;
export const selectCampaign = (state: RootState) =>
  state.campaignState;
export const selectCampaignNamesTotal = (state: RootState) =>
  state.campaignState.campaignNamesTotal;
export const selectCampaignNamesMeta = (state: RootState) =>
  state.campaignState.campaignNamesMeta;
export const selectCreateLoading = (state: RootState) =>
  state.campaignState.createLoading;
export const selectCampaignNames = (state: RootState) => state.campaignState.campaignNames;  
export const selectCampaignSearch = (state: RootState) => state.campaignState.search;
export const selectCampaignIsLoaded = (state: RootState) => state.campaignState.isLoaded;
export const selectCampaignStatusType = (state: RootState) => state.campaignState.campaignStatusType;
export const selectUserPromotions = (state: RootState) => state.campaignState.userPromotions;