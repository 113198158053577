import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { userService } from "services";
import { ActionPayload, MetaPagination, ParamType } from "types/Others";
import { UserTypes } from "types/admin/user";

export const getUsersFromStore = createAsyncThunk(
  "auth/getUsersFromStore",
  async (param: ParamType) => {
    return await userService.getUsers(param);
  }
);

export const getSalutationsStore = createAsyncThunk(
  "auth/getSalutationsStore",
  async (param: ParamType) => {
    const data = await userService.getSalutations(param);
    return data;
  }
);

type UserState = {
  users: any[];
  salutations: any[];
  isLoading: boolean;
  isLoaded: boolean;
  meta: MetaPagination;
  total: number;
  search: string;
};

const initialState: UserState = {
  users: [],
  salutations: [],
  isLoading: true,
  isLoaded: false,
  meta: {},
  total: 0,
  search: "",
};

export const userSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetLoader: (state, action?) => {
      state.isLoaded = false;
    },
    setUserSearchState: (state, action: PayloadAction<ActionPayload>) => {
      state.search = action.payload?.data;
    },
    resetUserList: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = false;
      })
      .addCase(getUsersFromStore.fulfilled, (state, action) => {
        const { data, meta, total } = action.payload;
        state.users = data?.map((item: UserTypes.UserData) => {
          item["roleName"] = item?.role?.title;
          return item;
        });
        state.isLoading = false;
        state.isLoaded = true;
        state.meta = meta;
        state.total = total;
      })
      .addCase(getUsersFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.users = [];
      })
      .addCase(getSalutationsStore.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSalutationsStore.fulfilled, (state, action) => {
        state.salutations = action.payload.data;
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(getSalutationsStore.rejected, (state) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.salutations = [];
      });
  },
});

export const { resetUserList, resetLoader, setUserSearchState } =
  userSlice.actions;

export default userSlice.reducer;
export const selectUser = (state: RootState) => state.userState;
export const selectUsers = (state: RootState) => state.userState.users;
export const selectSalutations = (state: RootState) =>
  state.userState.salutations;
export const selectUserSearch = (state: RootState) => state.userState.search;
