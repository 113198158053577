import { ConfigureStoreOptions, configureStore } from "@reduxjs/toolkit";
import appStateSlice from "./features/appStateSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import campaignOprationalSlice from "./features/campaignOprationalSlice";
import toneSlice from "./features/master/toneSlice";
import languageSlice from "./features/master/languageStateSlice";
import mannerSlice from "./features/master/mannerStateSlice";
import emotionContextSlice from "./features/master/emotionContextStateSlice";
import bussinessListSlice from "./features/bussinessListSlice";
import sentOtpByHashSlice from "./features/auth/sentOtpByHashSlice";
import setNewPasswordSlice from "./features/auth/setNewPasswordSlice";
import campaignSlice from "./features/campaignSlice";
import forgotPasswordSlice from "./features/auth/forgotPasswordSlice";
import loginAsUserSlice from "./features/auth/loginAsUserSlice";
import sentOtpByUserNameSlice from "./features/auth/sentOtpByUserNameSlice";
import pressReleaseSlice from "./features/pressReleaseSlice";
import notificationSlice from "./features/notificationSlice";
import userSlice from "./features/userSlice";
import commonMasterSlice from "./features/master/commonSlice";
import changePasswordSlice from "./features/auth/changePasswordSlice";
import brandSlice from "./features/master/brandSlice";
import divisionSlice from "./features/master/divisionSlice";
import specialistSlice from "./features/master/specialistSlice";
import dashboardSlice from "./features/dashboardSlice";
import eventSlice from "./features/eventSlice";

export const createStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined
) =>
  configureStore({
    reducer: {
      appState: appStateSlice,
      campaignOpr: campaignOprationalSlice,
      toneState: toneSlice,
      languageState: languageSlice,
      mannerState: mannerSlice,
      emotionContextState: emotionContextSlice,
      bussinessListState: bussinessListSlice,
      sentOtpByHashState: sentOtpByHashSlice,
      setNewPasswordState: setNewPasswordSlice,
      campaignState: campaignSlice,
      forgotPasswordState: forgotPasswordSlice,
      loginAsUserState:loginAsUserSlice,
      sentOtpByUserNameState:sentOtpByUserNameSlice,
      pressReleaseState: pressReleaseSlice,
      notificationState: notificationSlice,
      userState: userSlice,
      commonMasterState: commonMasterSlice,
      changePasswordState: changePasswordSlice,
      brandState: brandSlice,
      divisionState: divisionSlice,
      specialistState: specialistSlice,
      dashboardState: dashboardSlice,
      eventState: eventSlice
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
    ...options,
  });

export const store: any = createStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
