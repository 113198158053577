import BaseService from "../Base";
import ApiUrls from "../ApiUrl";
import { ParamType } from "types/Others";
import { NotificationType } from "types/common/notification";

export default class NotificationService extends BaseService {
  async getNotifications(params: ParamType) {
    try {
      const response = await this.post(`${ApiUrls.notification}/list`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async deleteNotifications(params: NotificationType.UpdateParam) {
    try {
      const response = await this.post(`${ApiUrls.notification}/delete`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async readNotifications(params: NotificationType.UpdateParam) {
    try {
      const response = await this.post(`${ApiUrls.notification}/read`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async unreadNotifications(params: NotificationType.UpdateParam) {
    try {
      const response = await this.post(`${ApiUrls.notification}/unread`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async unreadCountNotifications(params = {}) {
    try {
      const response = await this.post(`${ApiUrls.notification}/unread-count`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async getPublishingMedias(params = {}) {
    try {
      const response = await this.post(ApiUrls.publishingMediaList, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
}
