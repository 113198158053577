import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { campaignService } from "services";
import { ActionPayload, ApprovalStatus, MetaPagination, ParamType, StatusTypes } from "types/Others";
import { CampaignProps } from "types/users/Campaign";

export const getPressReleaseFromStore = createAsyncThunk(
  "auth/getPressReleaseFromStore",
  async (param: ParamType) => {
    return await campaignService.getPressRelease(param);
  }
);

export const getPRHistoryFromStore = createAsyncThunk(
  "auth/getPRHistoryFromStore",
  async (param: CampaignProps.PRHistoryProps) => {
    return await campaignService.getPRHistory(param);
  }
);

type PressReleaseState = {
  pressReleases: any[];
  isLoading: boolean;
  isLoaded: boolean;
  meta: MetaPagination;
  total: number;
  search: string;
  prStatusType: StatusTypes;
  prHistory: any[];
};

const initialState: PressReleaseState = {
  pressReleases: [],
  isLoading: true,
  isLoaded: false,
  meta: {
    page: 1,
    pageSize: 18,
    pageCount: 0
  },
  total: 0,
  search: "",
  prStatusType: ApprovalStatus.PENDING,
  prHistory: []
};

export const pressReleaseSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetLoader: (state, action?) => {
      state.isLoaded = false;
    },
    setPRSearchState: (state, action: PayloadAction<ActionPayload>) => {
      state.search = action.payload?.data;
      const pages: MetaPagination = state.meta;
      pages["page"] = 1;
      state.meta = pages;
    },
    setPRStatusState: (state, action: PayloadAction<ActionPayload>) => {
      state.prStatusType = action.payload?.data;
    },
    updatePRPaginationState: (state, action: PayloadAction<ActionPayload>) => {
      const pages: MetaPagination = state.meta;
      pages["page"] = action.payload?.data?.page || 1;
      state.meta = pages;
    },
    resetPR: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPressReleaseFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = false;
      }).addCase(getPressReleaseFromStore.fulfilled, (state, action) => {
        const { data, meta, total } = action.payload;
        state.pressReleases = data;
        state.isLoading = false;
        state.isLoaded = true;
        state.meta = meta;
        state.total = total;
      }).addCase(getPressReleaseFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.pressReleases = [];
      })
      .addCase(getPRHistoryFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = false;
      }).addCase(getPRHistoryFromStore.fulfilled, (state, action) => {
        state.prHistory = action.payload?.data;
        state.isLoading = false;
        state.isLoaded = true;
      }).addCase(getPRHistoryFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.prHistory = [];
      })
  },
});

export const { resetPR, resetLoader, setPRSearchState, setPRStatusState, updatePRPaginationState } = pressReleaseSlice.actions;

export default pressReleaseSlice.reducer;
export const selectPressRelease = (state: RootState) =>
  state.pressReleaseState;
export const selectPressReleases = (state: RootState) =>
  state.pressReleaseState.pressReleases;
export const selectPRSearch = (state: RootState) =>
  state.pressReleaseState.search;  
export const selectPRStatusType = (state: RootState) =>
state.pressReleaseState.prStatusType;  
export const selectPressReleaseMeta = (state: RootState) =>
  state.pressReleaseState.meta;
export const selectPRHistory = (state: RootState) => state.pressReleaseState.prHistory;
export const selectPRLoading = (state: RootState) => state.pressReleaseState.isLoading;