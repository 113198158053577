import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";
import { authService } from "services";
import { Auth } from "types/Auth";

export const setNewPasswordFromStore = createAsyncThunk(
  "auth/setNewPasswordFromStore",
  async (param: Auth.SetNewPassword) => {
    return await authService.setNewPassword(param);
  }
);

type SetNewPasswordState = {
  isLoading: boolean;
  isLoaded: boolean;
};

const initialState: SetNewPasswordState = {
  isLoading: true,
  isLoaded: false,
};

export const setNewPasswordSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetLoader: (state, action?) => {
      state.isLoaded = false;
    },
    resetSetNewPassword: () => initialState,
  },
  extraReducers: (builder) => {
    builder

      .addCase(setNewPasswordFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(setNewPasswordFromStore.fulfilled, (state, action) => {
        let { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(setNewPasswordFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      });
  },
});

export default setNewPasswordSlice.reducer;
export const { resetSetNewPassword, resetLoader } = setNewPasswordSlice.actions;
// export const selectIsVerified = (state: RootState) =>
//   state.verifyOtpByHashState.isOtpVerify;
