import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { languageService } from "services";
import { MetaPagination } from "types/Others";
import { Master } from "types/common/master";
import { setLanguagesState } from "../campaignOprationalSlice";
import { toastSuccess } from "helpers/toastHelper";

export const getLanguageFromStore = createAsyncThunk(
  "auth/getLanguageFromStore",
  async (param: Master.BodyParamType = {}, { dispatch }) => {
    const res = await languageService.getLanguage(param);
    if (!!res?.data)
      await dispatch(
        setLanguagesState({
          languages: res?.data?.map((item: any) => {
            item.isChecked = false;
            return item;
          }),
        })
      );
    return res;
  }
);

export const createLanguageFromStore = createAsyncThunk(
  "auth/createLanguageFromStore",
  async ({ createPayload, getPayload }: any, { dispatch }) => {
    const cr = await languageService.createLanguage(createPayload);
    const res = await dispatch(getLanguageFromStore(getPayload));
    return { message: cr?.message, res };
  }
);

export const updateLanguageFromStore = createAsyncThunk(
  "auth/updateLanguageFromStore",
  async ({ updatePayload, getPayload }: any, { dispatch }) => {
    const ur = await languageService.updateLanguage(updatePayload);
    await dispatch(getLanguageFromStore(getPayload));
    return { message: ur?.message };
  }
);

export const deleteLanguageFromStore = createAsyncThunk(
  "auth/deleteLanguageFromStore",
  async ({ delPayload, getPayload }: any, { dispatch }) => {
    const dr = await languageService.deleteLanguage(delPayload);
    await dispatch(getLanguageFromStore(getPayload));
    return { message: dr?.message };
  }
);

type LanguageState = {
  languageList: any[];
  isLoading: boolean;
  isLoaded: boolean;
  meta: MetaPagination;
  total: number;
};

const initialState: LanguageState = {
  languageList: [],
  isLoading: true,
  isLoaded: false,
  meta: {},
  total: 0,
};

export const languageSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetLoader: (state, action?) => {
      state.isLoaded = false;
    },
    resetLanguage: () => initialState,
  },
  extraReducers: (builder) => {
    builder

      // GET ACTION
      .addCase(getLanguageFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(getLanguageFromStore.fulfilled, (state, action) => {
        const { data, meta, total } = action.payload;
        state.languageList = data;
        state.meta = meta;
        state.total = total;
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(getLanguageFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // CREATE ACTION

      .addCase(createLanguageFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(createLanguageFromStore.fulfilled, (state, action) => {
        const { message, res } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(createLanguageFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // UPDATE ACTION

      .addCase(updateLanguageFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(updateLanguageFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(updateLanguageFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // DELETE ACTION

      .addCase(deleteLanguageFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(deleteLanguageFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(deleteLanguageFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      });
  },
});

export const { resetLanguage, resetLoader } = languageSlice.actions;

export default languageSlice.reducer;
export const selectLanguage = (state: RootState) => state.languageState;
export const selectLanguages = (state: RootState) => state.languageState;
