import BaseService from "../Base";
import ApiUrls from "../ApiUrl";
import { ParamType, RoleType } from "types/Others";

export default class DashboardService extends BaseService {
  async getDashboardStats(params: ParamType, type : RoleType) {
    try {
      const response = await this.post(`${type === "admin" ? ApiUrls.adminDashboard : ApiUrls.clientDashboard}/stats`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async getLastTenCampaigns() {
    try {
      const params = {
        "range":{
          "page":1,
          "pageSize":10
        },
        // "sort": {
        //   "orderDir": "asc"
        // }
      }
      const response = await this.post(`${ApiUrls.campaign}/list`, params);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
