import BaseService from "../../../Base";
import ApiUrls from "../../../ApiUrl";
import { Master } from "types/common/master";

export default class ToneService extends BaseService {
  async getTone(param:Master.BodyParamType) {
    try {
      const response = await this.post(`${ApiUrls.tone}/list`, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async createTone(params: Master.AdminType) {
    try {
      const response = await this.post(`${ApiUrls.tone}/create`, params);
      return response;
    } catch (error) {
      throw error;
    }
  }

  async updateTone(params: Master.AdminType) {
    try {
      const response = await this.post(`${ApiUrls.tone}/update`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteTone(params: Master.DeleteParamType) {    
    try {
      const response = await this.post(`${ApiUrls.tone}/delete`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
}
