import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";
import { authService } from "services";
import { Auth } from "types/Auth";

export const changePasswordFromStore = createAsyncThunk(
  "auth/changePasswordFromStore",
  async (param: Auth.ChangePassword) => {
    const response = await authService.changePassword(param);
    return response;
  }
);

type ChangePasswordState = {
  isLoading: boolean;
  isLoaded: boolean;
  isSuccess: boolean;
};

const initialState: ChangePasswordState = {
  isLoading: true,
  isLoaded: false,
  isSuccess: false
};

export const changePasswordSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetChangePasswordLoader: (state, action?) => {
      state.isLoaded = false;
    },
    resetChangePassword: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(changePasswordFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
        state.isSuccess = false;
      })
      .addCase(changePasswordFromStore.fulfilled, (state, action) => {
        let { message } = action?.payload;
        toastSuccess(message || "Password updated successfully.");
        state.isLoading = false;
        state.isLoaded = true;
        state.isSuccess = true;
      })
      .addCase(changePasswordFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.isSuccess = false;
      });
  },
});

export default changePasswordSlice.reducer;
export const { resetChangePassword, resetChangePasswordLoader } = changePasswordSlice.actions;
export const selectPasswordLoaded = (state: RootState) => state.changePasswordState.isLoaded;
export const selectPasswordSuccess = (state: RootState) => state.changePasswordState.isSuccess