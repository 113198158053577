import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { campaignService, dashboardService } from "services";
import { MetaPagination, ParamType, RoleWithParam } from "types/Others";
import { DashboardProps } from "types/common/Dashboard";
import { CampaignProps } from "types/users/Campaign";

export const getTopTenCampaignFromStore = createAsyncThunk(
  "auth/getTopTenCampaignFromStore",
  async () => {
    return await dashboardService.getLastTenCampaigns();
  }
);

export const getCurrentCampaignsPerformanceFromStore = createAsyncThunk(
  "auth/getCurrentCampaignsPerformanceFromStore",
  async (param: ParamType) => {
    return await campaignService.getCampaignNames(param);
  }
);

export const getDashboardStatsFromStore = createAsyncThunk(
  "auth/getDashboardStatsFromStore",
  async (param: ParamType) => {
    return await dashboardService.getDashboardStats(param, "client")
  }
);

export const getAdminDashboardStatsFromStore = createAsyncThunk(
  "auth/getAdminDashboardStatsFromStore",
  async (param: ParamType) => {
    return await dashboardService.getDashboardStats(param, "admin")
  }
);

type CampaignState = {
  topTenCampaigns: CampaignProps.CampaignNameProps[];
  currentCampaignsPerformance: CampaignProps.CampaignNameProps[];
  dashboardStats: DashboardProps.DashboardStats | null;
  adminDashboardStats: DashboardProps.AdminDashboardStats[] | [];
  isLoading: boolean;
  isLoaded: boolean;
  meta: MetaPagination;
  total: number;
};

const initialState: CampaignState = {
  topTenCampaigns: [],
  currentCampaignsPerformance: [],
  dashboardStats: null,
  adminDashboardStats: [],
  isLoading: true,
  isLoaded: false,
  meta: {},
  total: 0,
};

export const dashboardSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetDashboard: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTopTenCampaignFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = false;
      }).addCase(getTopTenCampaignFromStore.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.topTenCampaigns = data;
        state.isLoading = false;
        state.isLoaded = true;
      }).addCase(getTopTenCampaignFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

    builder
      .addCase(getCurrentCampaignsPerformanceFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = false;
      }).addCase(getCurrentCampaignsPerformanceFromStore.fulfilled, (state, action) => {
        const { data, meta, total } = action.payload;
        state.currentCampaignsPerformance = data;
        state.meta = meta;
        state.total = total;
        state.isLoading = false;
        state.isLoaded = true;
      }).addCase(getCurrentCampaignsPerformanceFromStore.rejected, (state, action) => {
        state.total = 0;
        state.isLoading = false;
        state.isLoaded = true;
        state.currentCampaignsPerformance = [];
      })  

    builder
      .addCase(getDashboardStatsFromStore.pending, (state, action) => {
        state.isLoading = true;
      }).addCase(getDashboardStatsFromStore.fulfilled, (state, action) => {
        const { data } = action?.payload;
        state.dashboardStats = data;
        state.isLoading = false;
      }).addCase(getDashboardStatsFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.dashboardStats = null;
      })  

    builder
      .addCase(getAdminDashboardStatsFromStore.pending, (state, action) => {
        state.isLoading = true;
      }).addCase(getAdminDashboardStatsFromStore.fulfilled, (state, action) => {
        const { data } = action?.payload;
        state.adminDashboardStats = data;
        state.isLoading = false;
      }).addCase(getAdminDashboardStatsFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.adminDashboardStats = [];
      })   
  },
});

export const { resetDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
export const selectDashboard = (state: RootState) =>
  state.dashboardState;
export const selectTopTenCampaigns = (state: RootState) =>
  state.dashboardState.topTenCampaigns;
export const selectCurrentCampaignsPerformance = (state: RootState) =>
  state.dashboardState.currentCampaignsPerformance;  
export const selectDashboardStats = (state: RootState) =>
  state.dashboardState.dashboardStats;    
export const selectAdminDashboardStats = (state: RootState) =>
  state.dashboardState.adminDashboardStats;  
export const selectCurrentCampaignTotal = (state: RootState) =>   
  state.dashboardState.total;