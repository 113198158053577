import { ApprovalStatus, StatusOptions } from "types/Others";

export const mediaTypes = [
  // {
  //   title: "Text",
  //   type: "text",
  //   fileFormat: ".pdf,.doc",
  //   isChecked: false
  // },
  // {
  //   title: "Video",
  //   type: "video",
  //   fileFormat: ".mp4",
  //   isChecked: false
  // },
  {
    title: "Image",
    type: "image",
    fileFormat: ".jpg,.jpeg,.webp,.png,.svg",
    isChecked: true
  },
  // {
  //   title: "Audio",
  //   type: "audio",
  //   fileFormat: ".mp3",
  //   isChecked: false
  // }
]
export const statusFilter: StatusOptions[] = [
  {
    title: "All",
    type: "",
  },
  {
    title: "Pending",
    type: ApprovalStatus.PENDING,
  },
  {
    title: "Approved",
    type: ApprovalStatus.APPROVED,
  },
  {
    title: "Published",
    type: ApprovalStatus.PUBLISHED
  },
  {
    title: "Archived",
    type: ApprovalStatus.ARCHIVED
  }
];