import ApiUrls from "services/ApiUrl";
import BaseService from "services/Base";
import { Master } from "types/common/master";

export default class PaymentService extends BaseService {
  async generateIframeLink(param: any = {}) {
    try {
      const response = await this.post(
        `${ApiUrls.paymentsService}/init`,
        param
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
}
