import BaseService from "../../Base";
import ApiUrls from "../../ApiUrl";
import { Master } from "types/common/master";

export default class DivisionService extends BaseService {
  async getDivision(param:Master.BodyParamType) {
    try {
      const response = await this.post(`${ApiUrls.division}/list`, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async createDivision(params: Master.AdminType) {
    try {
      const response = await this.post(`${ApiUrls.division}/create`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateDivision(params: Master.AdminType) {
    try {
      const response = await this.post(`${ApiUrls.division}/update`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteDivision(params: Master.DeleteParamType) {    
    try {
      const response = await this.post(`${ApiUrls.division}/delete`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
}
