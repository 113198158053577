import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";
import { notificationService } from "services";
import { ActionPayload, MetaPagination, ParamType, Range } from "types/Others";
import { NotificationType } from "types/common/notification";

export const getNotificationsFromStore = createAsyncThunk(
  "auth/getNotificationsFromStore",
  async (param: ParamType) => {
    return await notificationService.getNotifications(param);
  }
);

export const deleteNotificationsFromStore = createAsyncThunk(
  "auth/deleteNotificationsFromStore",
  async (param: NotificationType.UpdateParam, { dispatch }) => {
    const pagination: Range | undefined = param?.pagination;
    param?.pagination && delete param.pagination;
    const response = await notificationService.deleteNotifications(param);
    let payload: ParamType = {}
    if(pagination) payload["range"] = pagination;
    dispatch(getNotificationsFromStore(payload));
    return response;
  }
);

export const readNotificationsFromStore = createAsyncThunk(
  "auth/readNotificationsFromStore",
  async (param: NotificationType.UpdateParam, { dispatch }) => {
    const pagination: Range | undefined = param?.pagination;
    param?.pagination && delete param.pagination;
    const response = await notificationService.readNotifications(param);
    let payload: ParamType = {}
    if(pagination) payload["range"] = pagination;
    dispatch(getNotificationsFromStore(payload));
    return response;
  }
);

export const unreadNotificationsFromStore = createAsyncThunk(
  "auth/unreadNotificationsFromStore",
  async (param: NotificationType.UpdateParam, { dispatch }) => {
    const pagination: Range | undefined = param?.pagination;
    param?.pagination && delete param.pagination;
    const response = await notificationService.unreadNotifications(param);
    let payload: ParamType = {}
    if(pagination) payload["range"] = pagination;
    dispatch(getNotificationsFromStore(payload));
    return response;
  }
);

export const getAllUnreadNotificationsFromStore = createAsyncThunk(
  "auth/getAllUnreadNotificationsFromStore",
  async (param: any) => {
    const response = await notificationService.unreadCountNotifications(param);
    return response;
  }
);

type NotificationState = {
  notifications: any[];
  unreadCount: number;
  markAsReadLoaded: boolean;
  markAsUnReadLoaded: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  meta: MetaPagination;
  total: number;
  search: string;
};

const initialState: NotificationState = {
  notifications: [],
  unreadCount: 0,
  markAsReadLoaded: false,
  markAsUnReadLoaded: false,
  isLoading: true,
  isLoaded: false,
  meta: {
    page: 1,
    pageSize: 5,
    pageCount: 0,
  },
  total: 0,
  search: ""
};

export const notificationSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetLoader: (state, action?) => {
      state.isLoaded = false;
    },
    resetMarkAsReadLoader: (state, action?) => {
      state.markAsReadLoaded = false;
    },
    setNotificationSearch: (state, action: PayloadAction<ActionPayload>) => {
      state.search = action?.payload?.data;
    },
    updatePagination: (state, action?) => {
      const data = action?.payload;
      state.meta = {
        page: data?.page || state.meta?.page,
        pageSize: data?.pageSize || state.meta?.pageSize,
        pageCount: data?.pageCount || state.meta?.pageCount,
      };
    },
    resetNotification: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = false;
      })
      .addCase(getNotificationsFromStore.fulfilled, (state, action) => {
        const { data, meta, total } = action.payload;
        state.notifications = data;
        state.isLoading = false;
        state.isLoaded = true;
        state.meta = meta;
        state.total = total;
      })
      .addCase(getNotificationsFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.notifications = [];
      });

    builder
      .addCase(deleteNotificationsFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = false;
      })
      .addCase(deleteNotificationsFromStore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(deleteNotificationsFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      });

    builder
      .addCase(readNotificationsFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.markAsReadLoaded = false;
      })
      .addCase(readNotificationsFromStore.fulfilled, (state, action) => {
        const { message } = action.payload;
        if (!!message) toastSuccess(message);
        state.isLoading = false;
        state.markAsReadLoaded = true;
      })
      .addCase(readNotificationsFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.markAsReadLoaded = true;
      });

    builder
      .addCase(unreadNotificationsFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.markAsUnReadLoaded = false;
      })
      .addCase(unreadNotificationsFromStore.fulfilled, (state, action) => {
        const { message } = action.payload;
        if (!!message) toastSuccess(message);
        state.isLoading = false;
        state.markAsUnReadLoaded = true;
      })
      .addCase(unreadNotificationsFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.markAsUnReadLoaded = true;
      });
  
    builder.addCase(
      getAllUnreadNotificationsFromStore.fulfilled,
      (state, action) => {
        state.unreadCount = action?.payload?.count;
        state.isLoading = false;
        state.isLoaded = true;
      }
    );
  },
});

export const {
  resetNotification,
  resetLoader,
  resetMarkAsReadLoader,
  updatePagination,
  setNotificationSearch
} = notificationSlice.actions;

export default notificationSlice.reducer;
export const selectNotification = (state: RootState) => state.notificationState;
export const selectNotifications = (state: RootState) =>
  state.notificationState.notifications;
export const selectNotificationLoading = (state: RootState) =>
  state.notificationState.isLoading;

export const selectUnreadCount = (state: RootState) =>
  state.notificationState.unreadCount;
export const selectMarkAsReadLoaded = (state: RootState) =>
  state.notificationState.markAsReadLoaded;
  export const selectMarkAsUnReadLoaded = (state: RootState) =>
  state.notificationState.markAsUnReadLoaded;
export const selectNotificationMeta = (state: RootState) =>
  state.notificationState.meta;
export const selectNotificationTotal = (state: RootState) =>
  state.notificationState.total;
export const selectNotificationSearch = (state: RootState) =>
  state.notificationState.search;    
