import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { brandService } from "services";
import { MetaPagination } from "types/Others";
import { Master } from "types/common/master";
import { toastSuccess } from "helpers/toastHelper";
import { isObjectBlank } from "helpers/common";

export const getBrandFromStore = createAsyncThunk(
  "auth/getBrandFromStore",
  async (param: Master.BodyParamType, { dispatch }) => {
    if (isObjectBlank(param))
      param = {
        range: {
          page: 1,
          pageSize: 100,
        },
      };
    return await brandService.getBrand(param);
  }
);
export const createBrandFromStore = createAsyncThunk(
  "auth/createBrandFromStore",
  async ({ payload, getPayload }: any, { dispatch }) => {
    const res = await brandService.createBrand(payload);
    await dispatch(getBrandFromStore(getPayload));
    return res
  }
);

export const updateBrandFromStore = createAsyncThunk(
  "auth/updateBrandFromStore",
  async ({ payload, getPayload }: any, { dispatch }) => {
    const res = await brandService.updateBrand(payload);
    await dispatch(getBrandFromStore(getPayload));
    return res;
  }
);

export const deleteBrandFromStore = createAsyncThunk(
  "auth/deleteBrandFromStore",
  async ({ delPayload, getPayload }: any, { dispatch }) => {
    const res = await brandService.deleteBrand(delPayload);
    await dispatch(getBrandFromStore(getPayload));
    return res
  }
);

type BrandInitialState = {
  brands: any[];
  isLoading: boolean;
  isLoaded: boolean;
  meta: MetaPagination;
  total: number;
};

const initialState: BrandInitialState = {
  brands: [],
  isLoading: true,
  isLoaded: false,
  meta: {},
  total: 0,
};

export const brandSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetBrandLoader: (state, action?) => {
      state.isLoaded = false;
    },
    resetBrand: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // GET ACTION
      .addCase(getBrandFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(getBrandFromStore.fulfilled, (state, action) => {
        const { data, meta, total } = action?.payload;
        state.brands = data;
        state.meta = meta;
        state.total = total;
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(getBrandFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // CREATE ACTION
      .addCase(createBrandFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(createBrandFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(createBrandFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // UPDATE ACTION
      .addCase(updateBrandFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(updateBrandFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(updateBrandFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // DELETE ACTION
      .addCase(deleteBrandFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(deleteBrandFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(deleteBrandFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      });
  },
});

export const { resetBrandLoader, resetBrand } = brandSlice.actions;

export default brandSlice.reducer;
export const selectBrand = (state: RootState) => state.brandState;
export const selectBrands = (state: RootState) => state.brandState.brands;
