import React, { useEffect, useMemo, useRef, useState } from "react";
import assets, { Close, Logout, Search, UserProfile } from "assets";
import "./styles.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BurgerMenuIcon from "../../components/icons/BurgerMenu";
import BellIcon from "../../components/icons/Bell";

import {
  logoutStore,
  selectIsMobileView,
  selectIsShowSidebar,
  selectIsShowSuperAdminButton,
  selectIsSuperAdminRole,
  setAppState,
  updateIsShowSidebar,
  updateIsShowSuperAdmin,
} from "redux/features/appStateSlice";
import { useAppDispatch, useTypedSelector } from "redux/store";
import { Dropdown, Spinner } from "react-bootstrap";
import Confirmation from "./confirmation";
import { resetCampaignOprationState } from "redux/features/campaignOprationalSlice";
import {
  getOldToken,
  getOldUser,
  removeItem,
  storageKey,
} from "helpers/storage";
import { toastError } from "helpers/toastHelper";
import { resetBussinessList, setBLSearchState } from "redux/features/bussinessListSlice";
import { resetCampaignList, setCampaignSearchState } from "redux/features/campaignSlice";
import {
  deleteNotificationsFromStore,
  getAllUnreadNotificationsFromStore,
  getNotificationsFromStore,
  readNotificationsFromStore,
  resetNotification,
  selectNotificationLoading,
  selectNotifications,
  selectUnreadCount,
  setNotificationSearch,
} from "redux/features/notificationSlice";
import { resetPR, setPRSearchState } from "redux/features/pressReleaseSlice";
import { resetUserList, setUserSearchState } from "redux/features/userSlice";
import { checkCurrentPath, getDateFromNow, getFirstWordName, getTitleForSlug } from "helpers/common";
import { NotificationType } from "types/common/notification";
import { Loader } from "components/Loadable";
import { PageWiseRouteType, ParamType, Range } from "types/Others";
import { debounce } from "lodash";
import NoDataComponent from "components/nodata";
import AdminIcon from "components/icons/AdminIcon";
import { useAuth } from "hooks/useAuth";

const Topbar = () => {
  const [placeholderType, setPlaceholderType] =
    useState<PageWiseRouteType>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const auth: any = useAuth();
  const isSuperAdminRole = useTypedSelector(selectIsSuperAdminRole);
  const isShowSuperAdminButton = useTypedSelector(selectIsShowSuperAdminButton);
  const [confirmationShow, setConfirmationShow] = useState<boolean>(false);
  const [notificationId, setNotificationId] = useState<number | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const notifications = useTypedSelector(selectNotifications);
  const notificationLoading = useTypedSelector(selectNotificationLoading);
  const unreadCount = useTypedSelector(selectUnreadCount);
  const isShowSidebar = useTypedSelector(selectIsShowSidebar);
  const isMobileView = useTypedSelector(selectIsMobileView);
  const notifiactionPagination: Range = {
    page: 1,
    pageSize: 5,
  };
  const searchRef: any = useRef(null);

  useEffect(() => {
    if (!!location?.pathname) {
      if (checkCurrentPath("users")) {
        setPlaceholderType("user");
      } else if (checkCurrentPath("business-list")) {
        setPlaceholderType("business-client");
      } else if (checkCurrentPath("press-releases")) {
        setPlaceholderType("press-release");
      } else if (checkCurrentPath("campaigns")) {
        setPlaceholderType("campaign");
      } else if (checkCurrentPath("notifications")) {
        setPlaceholderType("notification");
      } else if (checkCurrentPath("clients")) {
        setPlaceholderType("client");
      } else {
        setPlaceholderType("");
      }
    }
    checkOldUserExist();
  }, [location.pathname]);

  const setSidebar = (show = false) => {
    dispatch(updateIsShowSidebar({show}));
  }

  const checkOldUserExist = async () => {
    const token = await getOldToken();
    const user = await getOldUser();
    if (!!token && !!user) {
      dispatch(updateIsShowSuperAdmin(true));
    }
    dispatch(getAllUnreadNotificationsFromStore({}));
  };

  const goBackToSuperAdmin = async () => {
    const token = await getOldToken();
    const user = await getOldUser();
    if (!!token && !!user) {
      dispatch(setAppState({ token, user, isRememberMe: true }));
      removeItem(storageKey.oldToken);
      removeItem(storageKey.oldUser);
      navigate("/business-list", { replace: true });
      cleanAllState();
    } else {
      toastError("User Not found!");
    }
    dispatch(updateIsShowSuperAdmin(false));
  };

  const cleanAllState = () => {
    dispatch(resetBussinessList());
    dispatch(resetCampaignOprationState());
    dispatch(resetCampaignList());
    dispatch(resetNotification());
    dispatch(resetPR());
    dispatch(resetUserList());
    dispatch(resetNotification());
  };

  const onClickLogout = () => {
    dispatch(logoutStore());
    cleanAllState();
    navigate("/", { replace: true });
  };

  const userToggle = React.forwardRef<
    HTMLImageElement,
    {
      children: React.ReactNode;
      onClick: (e: React.MouseEvent<HTMLImageElement>) => void;
    }
  >(({ children, onClick }, ref) => (
    <>
      {auth?.user?.profilePhoto ? (
        <img
          className="userImgTop"
          src={auth?.user?.profilePhoto}
          ref={ref}
          alt="Profile Img"
          width="30"
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        />
      ) : (
        <div
          className="userNameText"
          onClick={(e: any) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          <p className="loggedinText">{getFirstWordName(auth?.user?.firstName)}</p>
        </div>
      )}
    </>
  ));

  const notificationToggle = React.forwardRef<
    HTMLDivElement,
    {
      children: React.ReactNode;
      onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
    }
  >(({ children, onClick }, ref) => (
    <div
      className="notificationBtn"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {/* <img src={assets.images.bellIcon} alt="Bell Icon" width="22" /> */}
      <BellIcon/>

      {!!unreadCount && (
        <span className="notifybadge">
          <img
            alt="Bell"
            src={assets.images.notificationNumber}
            width="12"
            height="auto"
          />
        </span>
      )}
    </div>
  ));

  const handleClearClick = () => {
    // Close the dropdown by toggling the state
    setDropdownOpen(!dropdownOpen);
  };

  const onClickProfile = () => {
    navigate("/account-settings");
  };

  const onClickNotification = async (item: NotificationType.NotificationDetail) => {
    if (!!item?.notificationId) {
      if(!item?.isRead){
        const payload: NotificationType.UpdateParam = {
          notificationId: [item?.notificationId],
          pagination: notifiactionPagination,
        };
        await dispatch(readNotificationsFromStore(payload));
      }
    
      if(item?.moduleSlug === "press-release"){
        navigate(`/press-releases/view/${item?.referenceId}`);
        setDropdownOpen(!dropdownOpen);
      }
    }
  };

  const notificationDeleteHandler = () => {
    if (!!notificationId) {
      dispatch(
        deleteNotificationsFromStore({
          notificationId: [notificationId],
          pagination: notifiactionPagination,
        })
      );
      setNotificationId(null);
      setDropdownOpen(!dropdownOpen);
    }
  };

  const showConfirmation = useMemo(() => {
    if (!confirmationShow && !notificationId) return;
    return (
      <Confirmation
        show={confirmationShow || !!notificationId}
        onHide={() => {
          setConfirmationShow(false);
          setNotificationId(null);
        }}
        onConfirmation={() => {
          if (confirmationShow) onClickLogout();
          if (!!notificationId) notificationDeleteHandler();
        }}
        title={confirmationShow ? "Logout Confirmation" : "Delete Notification"}
        description={`Are you sure you want to ${
          confirmationShow ? "logout" : "delete this notification"
        }?`}
        actionBtnName="Yes"
        actionBtnColor="white"
        actionBtnBgColor="#00b8c2"
      />
    );
  }, [confirmationShow, notificationId]);

  const handleNotificationClick = async () => {
    let payload: ParamType = {
      range: notifiactionPagination
    }
    dispatch(getNotificationsFromStore(payload));
  };

  const onClickShowAll = () => {
    navigate("/notifications");
    setDropdownOpen(!dropdownOpen);
  };

  const onClickClose = (id: number) => {
    setNotificationId(id);
  };

  const debouncedSearch = debounce(async (text: string) => {
    const payload = {data: text};
    setLoading(true);
    if(placeholderType === "campaign") dispatch(setCampaignSearchState(payload));
    else if(placeholderType === "user") dispatch(setUserSearchState(payload));
    else if(placeholderType === "business-client") dispatch(setBLSearchState(payload));
    else if(placeholderType === "press-release") dispatch(setPRSearchState(payload));
    else if(placeholderType === "notification") dispatch(setNotificationSearch(payload));
    else if(placeholderType === "client") dispatch(setBLSearchState(payload));
    setTimeout(() => { setLoading(false);}, 400);
  }, 400);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  }

  const onClearClose = () => {
    if(!!searchRef?.current?.value){
      debouncedSearch("");
      searchRef.current.value = "";
      setLoading(true);
    }
  }

  return (
    <>
      <header>
        <nav className="navbar shadow-sm">
          <div className="container-fluid">
            <div className="__left">
              {isMobileView && (
                <Link to="#" className="closeIcon" onClick={() => setSidebar(!isShowSidebar)}> 
                  <BurgerMenuIcon />
                </Link>
              )}

              <div className="search-box">
                <input
                  ref={searchRef}
                  className="search-input"
                  type="text"
                  onChange={onChangeSearch}
                  placeholder={`Search ${placeholderType ? getTitleForSlug(placeholderType) : ""}`}
                />
                <a href="#" className="search-btn">
                {!loading ? (
                  <img src={!searchRef?.current?.value ? Search : Close} width={!searchRef?.current?.value ? 20 : 18} height={!searchRef?.current?.value ? 20 : 18} alt="Search" onClick={onClearClose}/>
                ) : (
                  <Spinner className="spinner" animation="border" size={"sm"}/>
                )}
                </a>
              </div>
            </div>
      
            <div className="__right">
              <div className="user-area d-flex gap-3 align-items-center">
                {isShowSuperAdminButton && (
                  <>
                  {isMobileView ? (
                    <div onClick={goBackToSuperAdmin}>
                      <AdminIcon />
                    </div>
                    ) : (
                      <button
                        type="button"
                        className="btn createButtonStyle"
                        onClick={goBackToSuperAdmin}
                      >
                        Back to admin
                      </button>
                    )}
                  </>
                )}{" "}
                {/* <img
                  src={assets.images.appIcon}
                  alt="App Icon"
                  width="7"
                  height="27"
                  className="appIcon"
                /> */}
                {/* <VerticalMenuIcon/> */}
                {/* <img src={BellIcon} alt="Bell Icon" width="30" /> */}
                <Dropdown
                  show={dropdownOpen}
                  onToggle={() => setDropdownOpen(!dropdownOpen)}
                  onClick={handleNotificationClick}
                >
                  <Dropdown.Toggle
                    as={notificationToggle}
                    id="dropdown-custom-components-1"
                  ></Dropdown.Toggle>

                  <Dropdown.Menu className="notificationDropdown">
                    <div className="notificationHeader">
                      <p className="heading heading1">Notifications</p>
                      <p onClick={handleClearClick} className="heading heading2">
                        Clear
                      </p>
                    </div>
                    <div className="notificationBody">
                      {!!notifications?.length
                        ? notifications?.map((item: NotificationType.NotificationDetail, index: number) => {
                            return (
                              <div
                                key={index}
                                className={`item ${!item?.isRead ? "activeItem" : ""}`}
                                onClick={() => onClickNotification(item)}
                              >
                                <div className="d-flex align-items-center">
                                  <img
                                    src={item?.logo}
                                    alt="PR"
                                    width={50}
                                    height={50}
                                    className="itemIcon"
                                  />
                                </div>
                                <div style={{ width: "72%" }}>
                                  <p className="notificationText">
                                    {" "}
                                    {item?.title}
                                  </p>
                                  <p className="time">
                                    {getDateFromNow(item?.createdAt)}
                                  </p>
                                </div>
                                <div
                                  className="closeBtn"
                                  onClick={(event) => {
                                    event?.stopPropagation();
                                    onClickClose(item?.notificationId);
                                  }}
                                >
                                  <img
                                    src={Close}
                                    alt="Close"
                                    width={20}
                                    height={20}
                                  />
                                </div>
                              </div>
                            );
                          })
                        : notificationLoading ? (
                            <Loader customClassName="notifyLoader" />
                          ) : !notifications?.length && !notificationLoading && (
                            <NoDataComponent message="No notification found!"/>
                          )}
                    </div>
                    {notifications?.length > 0 && (
                      <div className="text-center py-2">
                        <span
                          onClick={onClickShowAll}
                          className="heading2 active-text-color"
                        >
                          Show all recent activity
                        </span>
                      </div>
                    )}

                    {/*                 
                  <Dropdown.Item eventKey="12" className="item">
                    <div className="d-flex align-items-center">
                      <img
                        src="https://placehold.co/100x100"
                        alt=""
                        width={50}
                        height={50}
                        className="itemIcon"
                      />
                      <div>
                        <p className="notificationText">Notification Item 2</p>
                        <p className="time">12:33 pm, Thurs</p>
                      </div>
                    </div>
                    <div className="closeBtn">
                      <img src={Close} alt="" width={20} height={20} />
                    </div>
                  </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>

                {/* <img src={UserIcon} alt="User Icon" width="30" /> */}
                <Dropdown className="userDropdown">
                  <Dropdown.Toggle
                    as={userToggle}
                    id="dropdown-custom-components-2"
                  ></Dropdown.Toggle>

                  <Dropdown.Menu>
                    {!isSuperAdminRole && (
                      <Dropdown.Item eventKey="21" onClick={onClickProfile}>
                        <img src={UserProfile} alt="UserProfile" width="20" />{" "}
                        View Profile
                      </Dropdown.Item>
                    )}

                    {/* <Dropdown.Item eventKey="22" onClick={onClickLogout}>
                    <img src={Logout} alt="Logout" width="25" /> Logout
                  </Dropdown.Item> */}
                    <Dropdown.Item
                      eventKey="22"
                      onClick={() => setConfirmationShow(true)}
                    >
                      <img src={Logout} alt="Logout" width="25" /> Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </nav>
      </header>
      {showConfirmation}
    </>
  );
};

export default Topbar;
