// All SVG Imports
import Logo from "./svg/logo.svg";
import HomeBold from "./svg/home-bold.svg";
import NewCampaignBold from "./svg/new-campaigne-bold.svg";
import ManageBold from "./svg/manage-bold.svg";
import Analytics from "./svg/analytics.svg";
import UserIcon from "./svg/user.svg";
import Logout from "./svg/logout.svg";
import UserProfile from "./svg/user-profile.svg";
import Delete from "./svg/delete.svg";
import Search from "./svg/seach.svg";
import Close from "./svg/close.svg";
import List from "./svg/list.svg";
import UserList from "./svg/userList.svg";
import Master from "./svg/master.svg";
import BusinessListIcon from "./svg/business-list.svg";
import UserAccessIcon from "./svg/user-access.svg";
import PackageIcon from "./svg/package.svg";
import RightCircleArrow from "./svg/right-circle-arrow.svg";
import RightArrow from "./svg/right-arrow.svg";
import Login from "./svg/login.svg";
import ArrowDown from "./svg/arrow-down.svg";
import ArrowUp from "./svg/arrow-up.svg";
import editIcon from "./svg/edit.svg";
import uploadIcon from "./svg/file-upload.svg";
import pauseIcon from "./svg/pauseIcon.svg";
import InfoIcon from "./svg/info.svg";
import ViewIcon from "./svg/view.svg";
import MarkEmailReadIcon from "./svg/mark_email_read.svg";
import MarkEmailUnReadIcon from "./svg/mark_email_unread.svg";
import Archive from "./svg/archive.svg";
import UnArchive from "./svg/unarchive.svg";
import ArchiveWhite from "./svg/archive-white.svg";
import UnArchiveWhite from "./svg/unarchive-white.svg";
import Upload from "./svg/upload.svg";
import CopyIcon from "./svg/copy.svg";
import Resend from "./svg/reSend.svg";
import verticalMenu from "./svg/verticalMenu.svg";
import bell from "./svg/bell.svg";
import burgerMenu from "./svg/burger-menu.svg";
import adminIcon from "./svg/admin-logo.svg";
import DownloadIcon from "./svg/download.svg";
import UserProfileGreyIcon from "./svg/user-profile-grey.svg";
import EditFileIcon from "./svg/file-edit.svg";
import UploadWhiteIcon from "./svg/upload-white.svg";
import CloseErrorIcon from "./svg/close-error.svg";
import TickSuccessIcon from "./svg/check-success.svg";
import CloseGreyIcon from "./svg/icons8-close.svg";
import BackIcon from "./svg/back-icon.svg";
import HistoryWhiteIcon from "./svg/history-white.svg";

const assets = {
  images: {
    shotLogo: require("./images/shot-logo.png"),
    grayLogo: require("./images/gray_logo.png"),
    digitalMarketing: require("./images/digital-marketing.png"),
    socialNetwork: require("./images/social-network.png"),
    instaPost: require("./images/post.png"),
    postLinked: require("./images/postLinked.png"),
    twitter: require("./images/twitter.png"),
    email: require("./images/email.png"),
    blog: require("./images/blog.png"),
    pressRelease: require("./images/press-release.png"),
    bullhorn: require("./images/bullhorn.png"),
    whatsapp: require("./images/whatsapp.png"),
    ads: require("./images/ads.png"),
    printer: require("./images/printer.png"),
    research: require("./images/research.png"),
    likes: require("./images/likes.png"),
    webPage: require("./images/web-page.png"),
    contentMarketing: require("./images/content-marketing.png"),
    contract: require("./images/contract.png"),
    bellIcon: require("./images/bellIcon.png"),
    appIcon: require("./images/appIcon.png"),
    notificationNumber: require("./images/notificationNumber.png"),
    webLogo: require("./images/logo-web.png"),
    emailDark: require("./images/email-dark.png"),
    phoneDark: require("./images/smartphone.png")
  },
};

export default assets;

// All SVG exports
export {
  Logo,
  HomeBold,
  NewCampaignBold,
  ManageBold,
  Analytics,
  UserIcon,
  Logout,
  UserProfile,
  Delete,
  Search,
  Close,
  List,
  UserList,
  Master,
  BusinessListIcon,
  PackageIcon,
  UserAccessIcon,
  RightCircleArrow,
  RightArrow,
  Login,
  ArrowDown,
  ArrowUp,
  editIcon,
  uploadIcon,
  pauseIcon,
  InfoIcon,
  ViewIcon,
  MarkEmailReadIcon,
  MarkEmailUnReadIcon,
  Archive,
  ArchiveWhite,
  UnArchive,
  UnArchiveWhite,
  Upload,
  CopyIcon,
  Resend,
  verticalMenu,
  bell,
  burgerMenu,
  adminIcon,
  DownloadIcon,
  UserProfileGreyIcon,
  EditFileIcon,
  UploadWhiteIcon,
  CloseErrorIcon,
  TickSuccessIcon,
  CloseGreyIcon,
  BackIcon,
  HistoryWhiteIcon
};
