import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { divisionService } from "services";
import { MetaPagination } from "types/Others";
import { Master } from "types/common/master";
import { toastSuccess } from "helpers/toastHelper";
import { isObjectBlank } from "helpers/common";

export const getDivisionFromStore = createAsyncThunk(
  "auth/getDivisionFromStore",
  async (param: Master.BodyParamType, { dispatch }) => {
    if(isObjectBlank(param)) param = {
      range: { 
        page: 1,
        pageSize: 100
      }
    }
    return await divisionService.getDivision(param);
  }
);
export const createDivisionFromStore = createAsyncThunk(
  "auth/createDivisionFromStore",
  async ({ payload, getPayload }: any, { dispatch }) => {
    const res = await divisionService.createDivision(payload);
    await dispatch(getDivisionFromStore(getPayload));
    return res;
  }
);

export const updateDivisionFromStore = createAsyncThunk(
  "auth/updateDivisionFromStore",
  async ({ payload, getPayload }: any, { dispatch }) => {
    const res = await divisionService.updateDivision(payload);
    await dispatch(getDivisionFromStore(getPayload));
    return res;
  }
);

export const deleteDivisionFromStore = createAsyncThunk(
  "auth/deleteDivisionFromStore",
  async ({ delPayload, getPayload }: any, { dispatch }) => {
    const res = await divisionService.deleteDivision(delPayload);
    await dispatch(getDivisionFromStore(getPayload));
    return res;
  }
);

type DivisionInitialState = {
  divisions: any[];
  isLoading: boolean;
  isLoaded: boolean;
  meta: MetaPagination;
  total: number;
};

const initialState: DivisionInitialState = {
  divisions: [],
  isLoading: true,
  isLoaded: false,
  meta: {},
  total: 0,
};

export const divisionSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetDivisionLoader: (state, action?) => {
      state.isLoaded = false;
    },
    resetDivision: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // GET ACTION
      .addCase(getDivisionFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(getDivisionFromStore.fulfilled, (state, action) => {
        const { data, meta, total } = action?.payload;
        state.divisions = data;
        state.meta = meta;
        state.total = total;
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(getDivisionFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // CREATE ACTION
      .addCase(createDivisionFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(createDivisionFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(createDivisionFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // UPDATE ACTION
      .addCase(updateDivisionFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(updateDivisionFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(updateDivisionFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // DELETE ACTION
      .addCase(deleteDivisionFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(deleteDivisionFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(deleteDivisionFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      });
  },
});

export const { resetDivisionLoader, resetDivision } = divisionSlice.actions;

export default divisionSlice.reducer;
export const selectDivision = (state: RootState) => state.divisionState;
export const selectDivisions = (state: RootState) => state.divisionState.divisions;
export const selectDivisionLoading = (state: RootState) => state.divisionState.isLoading;
export const selectDivisionTotal = (state: RootState) => state.divisionState.total;