import React from 'react'
import "./styles.scss";

interface Props {
  labelText?: String;
  type?: 'radio' | 'checkbox';
  name?: string;
  id?: string;
  checked?: boolean;
  value?: string;
  disabled?: boolean;
  onChange?: (event: any) => void;
  onClick?: (event: any) => void;
}

function Radio({
  labelText = "",
  type = "radio",
  name = "",
  id = "",
  value = "",
  checked = false,
  disabled = false,
  onChange = () => null,
  onClick=()=>null
}: Props) {
  return (
    <div className='radio-area'>
       <input
          type={type}
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          onClick={onClick}
          style={{ accentColor: "#D76A33"}}
        />
        <label htmlFor={id}>{labelText}</label>
    </div>
  )
}

export default Radio;

