import BaseService from "../Base";
import ApiUrls from "../ApiUrl";
import axios from "axios";


export default class AiService extends BaseService {
  async getAiContent(params :any) {
    try {
        params["signal"] = this.newAbortSignal(this.abortTime);
        const config = this.setHeaderFromParams({});
        const response = await axios.post(`${process.env.REACT_APP_AI_API_BASE_URL}${ApiUrls.generateArticle}`, params);
        // const response = await this.post(`${ApiUrls.generateHeadlinesNew}`,params)
        return response.data;
    } catch (error) {
      throw error;
    }
  }
  
  async getAiHeadlines(params:any={}){
    try{
      params["signal"] = this.newAbortSignal(this.abortTime);
      const config = this.setHeaderFromParams({});
      const response = await axios.post(`${process.env.REACT_APP_AI_API_BASE_URL}${ApiUrls.generateHeadlinesNew}`, params);
      // const response = await this.post(`${ApiUrls.generateHeadlinesNew}`,params)
      return response.data;
    }catch(error){
      throw(error)
    }
  }

  async generateKeywords(params:any) {
    try{
        params["signal"] = this.newAbortSignal(this.abortTime);
        const config = this.setHeaderFromParams({});
        const response = await axios.post(`${process.env.REACT_APP_AI_API_BASE_URL}${ApiUrls.generateKeywords}`, params);
        // const response = await this.post(`${ApiUrls.generateHeadlinesNew}`,params)
        return response.data;
    }catch(error){
        throw(error)
    }
  }
 
  async getModels(params:any={}){
    try{
      params["signal"] = this.newAbortSignal(this.abortTime);
      const config = this.setHeaderFromParams({});
      const response = await axios.post(`${process.env.REACT_APP_AI_API_BASE_URL}${ApiUrls.generateModels}`, params);
      // const response = await this.post(`${ApiUrls.generateModels}`,params)
      return response.data;
    }catch(error){
      throw(error)
    }
  }

}

