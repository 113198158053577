const ApiUrls = {
  //Auth APIs Url
  loginAs: "/v2/auth/login-as",
  login: "/v2/auth/sign-in",
  sendOTP: "/v1/auth/send-otp",
  resetPassword: "/v1/auth/reset-password",
  verifyPassword: "/v1/auth/verify-otp",
  refreshToken: "/v2/auth/refresh-token",
  register: "/v2/auth/register",
  sentOtpByHash: "/v2/auth/send-otp-hash",
  verifyOtpByHash: "/v2/auth/verify-otp-by-hash",
  setNewPassword: "/v2/auth/set-new-password",
  forgotPassword: "/v2/auth/forgot-password",
  changePassword: "/v2/auth/change-password",
  sentOtpByUserName: "/v2/auth/send-otp-by-username",
  generateTempToken: "/v2/auth/generate-temporary-token",
  getMe: "/v2/auth/get-me",
  signOut: "/v2/auth/sign-out",
  

  //Admin Master APIs Url
  tone: "/v2/tone",
  language: "/v2/language-style",
  manner: "/v2/manner",
  emotionContext: "/v2/emotional-context",
  contentType:"/v2/content-type",
  informationType:"/v2/information-type",
  contentService:"/v2/content-service",
  paymentsService:"/v2/payment",
  orderService:"/v2/order",
  productsService:"/v2/products",

  // Client APIs
  user: "/v2/user",
  campaign: "/v2/campaign",
  subCampaign: "/v2/sub-campaign",
  campaignType: "/v2/campaign-type",
  campaignMedium: "/v2/campaign-medium",
  campaignSupport: "/v2/campaign-support",
  wordCountRange: "/v2/word-count-range",
  lan: "/v2/language",
  generateHeadlines: "/v2/press-release/generate-headlines",

  //herehere
  generateHeadlinesNew: "/v1/content-generator/headlines",
  generateModels: "/v1/ai-details/list",
  generateKeywords: "/v1/content-generator/keywords",
  generateArticle: "/v1/content-generator/article",
  generateArticleByReleaseId: "/v1/content-generator/article-by-keywords",


  pressRelease: "/v2/press-release",
  prComment: "/v2/pr-comment",

  notification: "/v2/in-app-alert",
  salutation: "/v2/user/salutation/list",
  publishingMediaList: "/v2/publishing-media/list",
  campaignPublish: "/v2/campaign-publishing",

  mediaURL: "/v2/cdn/upload-multiple-files",
  division: "/v2/division",
  brand: "/v2/brand",
  specialist: "/v2/specialist",
  userPromotion: "/v2/user-promotion",

  resendVerification: "/v2/auth/resend-verification-link",
  userRolesAndPermission: "/v2/roles-and-permission",
  settingList: "/v2/setting/list",
  clientDashboard: "/v2/dashboard-client",
  adminDashboard: "/v2/dashboard-admin"
};

export default ApiUrls;
