import BaseService from "../../Base";
import ApiUrls from "../../ApiUrl";
import { Master } from "types/common/master";

export default class SpecialistService extends BaseService {
  async getSpecialist(param:Master.BodyParamType) {
    try {
      const response = await this.post(`${ApiUrls.specialist}/list`, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async createSpecialist(params: Master.AdminType) {
    try {
      const response = await this.post(`${ApiUrls.specialist}/create`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateSpecialist(params: Master.AdminType) {
    try {
      const response = await this.post(`${ApiUrls.specialist}/update`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteSpecialist(params: Master.DeleteParamType) {    
    try {
      const response = await this.post(`${ApiUrls.specialist}/delete`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
}
