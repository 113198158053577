import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { emotionContextService, mannerService } from "services";
import { MetaPagination } from "types/Others";
import { Master } from "types/common/master";
import { setEmotionalContextState } from "../campaignOprationalSlice";
import { toastSuccess } from "helpers/toastHelper";

export const getEmotionContextFromStore = createAsyncThunk(
  "auth/getEmotionContextFromStore",
  async (param: Master.BodyParamType = {}, { dispatch }) => {
    const res = await emotionContextService.getEmotionContext(param);
    if (!!res?.data)
      await dispatch(
        setEmotionalContextState({
          emotionalContext: res?.data?.map((item: any) => {
            item.isChecked = false;
            return item;
          }),
        })
      );
    return res;
  }
);

export const createEmotionContextFromStore = createAsyncThunk(
  "auth/createEmotionContextFromStore",
  async ({ createPayload, getPayload }: any, { dispatch }) => {
    const cr = await emotionContextService.createEmotionContext(createPayload);
    const res = await dispatch(getEmotionContextFromStore(getPayload));
    return { message: cr?.message, res };
  }
);

export const updateEmotionContextFromStore = createAsyncThunk(
  "auth/updateEmotionContextFromStore",
  async ({ updatePayload, getPayload }: any, { dispatch }) => {
    const ur = await emotionContextService.updateEmotionContext(updatePayload);
    await dispatch(getEmotionContextFromStore(getPayload));
    return { message: ur?.message };
  }
);

export const deleteEmotionContextFromStore = createAsyncThunk(
  "auth/deleteEmotionContextFromStore",
  async ({ delPayload, getPayload }: any, { dispatch }) => {
    const dr = await emotionContextService.deleteEmotionContext(delPayload);
    await dispatch(getEmotionContextFromStore(getPayload));
    return { message: dr?.message };
  }
);

type EmotionContextState = {
  emotionContextList: any[];
  isLoading: boolean;
  isLoaded: boolean;
  meta: MetaPagination;
  total: number;
};

const initialState: EmotionContextState = {
  emotionContextList: [],
  isLoading: true,
  isLoaded: false,
  meta: {},
  total: 0,
};

export const emotionContextSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetLoader: (state, action?) => {
      state.isLoaded = false;
    },
    resetEmotionContext: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // .addCase(getEmotionContextFromStore.fulfilled, (state, action) => {
      //   const { data, meta, total } = action.payload;
      //   state.emotionContextList = data;
      //   state.meta = meta;
      //   state.total = total;
      //   state.isLoading = false;
      //   state.isLoaded = true;
      // })
      // .addCase(getEmotionContextFromStore.rejected, (state, action) => {
      //   state.total = 0;
      //   state.isLoading = false;
      //   state.isLoaded = true;
      //   state.emotionContextList = [];
      // });

      // GET ACTION
      .addCase(getEmotionContextFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(getEmotionContextFromStore.fulfilled, (state, action) => {
        const { data, meta, total } = action.payload;
        state.emotionContextList = data;
        state.meta = meta;
        state.total = total;
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(getEmotionContextFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // CREATE ACTION

      .addCase(createEmotionContextFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(createEmotionContextFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(createEmotionContextFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // UPDATE ACTION

      .addCase(updateEmotionContextFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(updateEmotionContextFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(updateEmotionContextFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // DELETE ACTION

      .addCase(deleteEmotionContextFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(deleteEmotionContextFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(deleteEmotionContextFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      });
  },
});

export const { resetEmotionContext, resetLoader } = emotionContextSlice.actions;

export default emotionContextSlice.reducer;
export const selectEmotionContext = (state: RootState) =>
  state.emotionContextState;
export const selectEmotionContexts = (state: RootState) =>
  state.emotionContextState;
