import BaseService from "../Base";
import ApiUrls from "../ApiUrl";
import { UserTypes } from "types/admin/user";
import { ParamType } from "types/Others";

export default class UserService extends BaseService {
  async createUser(
    params: UserTypes.UserForm | UserTypes.UserStatus,
    type: "create" | "update"
  ) {
    try {
      const response = await this.post(`${ApiUrls.user}/${type}`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async getUsers(params: ParamType) {
    try {
      const response = await this.post(`${ApiUrls.user}/list`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async deleteUser(params = {}) {
    try {
      const response = await this.post(`${ApiUrls.user}/delete`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async getSalutations(params = {}) {
    try {
      const response = await this.post(`${ApiUrls.salutation}`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async resendVerification(params = {}) {
    try {
      const response = await this.post(`${ApiUrls.resendVerification}`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async createBulkUser(params = {}) {
    try {
      const response = await this.post(`${ApiUrls.user}/import`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async getBulkUser(params = {}) {
    try {
      const response = await this.post(`${ApiUrls.user}/export`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async getUserSetting(params = {}) {
    try {
      const response = await this.post(`${ApiUrls.settingList}`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
}
