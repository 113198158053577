import assets, { Close } from "assets";
import { Link, useLocation } from "react-router-dom";
import "./styles.scss";
import { PermissionType, RouteType } from "routes/config";
import userRoutes, { agencyRoutes, superAdminRoutes } from "routes/appRoutes";
import { useAppDispatch, useTypedSelector } from "redux/store";
import {
  selectDashboardSidebarClickCount,
  selectIsAgencyRole,
  selectIsMarketerRole,
  selectIsMobileView,
  selectIsShowSidebar,
  selectIsSuperAdminRole,
  selectPermissions,
  updateDashboardSidebarClickCount,
  updateIsShowSidebar,
} from "redux/features/appStateSlice";
import {
  getCampaignMediumStore,
  getCampaignSupportStore,
  getCampaignTypeStore,
  resetCampaignFormState,
  resetCampaignOprationState,
  restartContentDetailsState
} from "redux/features/campaignOprationalSlice";

const Sidebar = () => {
  const isSuperAdminRole = useTypedSelector(selectIsSuperAdminRole);
  const isAgencyRole = useTypedSelector(selectIsAgencyRole);
  const isMarketerRole = useTypedSelector(selectIsMarketerRole);
  const count = useTypedSelector(selectDashboardSidebarClickCount);
  const isShowSidebar = useTypedSelector(selectIsShowSidebar);
  const isMobileView = useTypedSelector(selectIsMobileView);
  const permissions = useTypedSelector(selectPermissions);
  const dispatch = useAppDispatch();
  const location = useLocation();
  
  const onClickNav = (index = 0) => {
    if (index === 0)
      dispatch(updateDashboardSidebarClickCount(Number(count) + 1));
    if(location?.pathname?.includes("campaign/create") || location?.pathname?.includes("campaign/update")) {
      dispatch(resetCampaignFormState());
      dispatch(resetCampaignOprationState());
      dispatch(restartContentDetailsState({}));
      dispatch(getCampaignTypeStore({}));
      dispatch(getCampaignMediumStore({}));
      dispatch(getCampaignSupportStore({}));
    }
    setSidebar(!isShowSidebar);
  };

  const setSidebar = (show = false) => {
    dispatch(updateIsShowSidebar({show}));
  }

  const reloadPage = (path: string = "") => {
    if(!path) return;
    return location?.pathname?.includes("campaign/update") && path?.includes("campaign/create");
  }

  const renderSideRoute = (routes: RouteType[] = []) => {
    if (!routes || !routes.length) return "";
    return routes.map((route: RouteType, index: number) => (
      <li
        key={route.path}
        className={`${!isSuperAdminRole ? "client_common" : ""} ${
          !!location?.pathname?.includes(route.path)
            ? !!isSuperAdminRole
              ? "active-adminSidebar"
              : "active-clientSidebar"
            : !isSuperAdminRole
            ? "client_li"
            : "admin_li"
        }`}
      >
        <Link
          className={!!isSuperAdminRole ? "admin_content" : "client_content"}
          to={route.path}
          onClick={() => onClickNav(index)}
          reloadDocument={reloadPage(route.path)}
        >
          <span
            className={
              !isSuperAdminRole ? "client_route-img" : "admin_route-img"
            }
          >
            {route.sidebarProps?.img}
          </span>
          {route.sidebarProps?.displayText}
        </Link>
      </li>
    ));
  };

  const getAdminUserSidebar = (routesArr: RouteType[] = [], permissionArr: PermissionType[] = []) => {
    return routesArr?.filter((route: RouteType) => {
      return permissionArr?.some((permission: PermissionType) => (permission?.module === route?.state && permission?.is_show))
    })
  }
  
  return (
    <div className={`sidebar ${!!isSuperAdminRole ? "roleOne" : "roleTwo"} ${(isShowSidebar && isMobileView) ? 'roleTwo-left0' : isMobileView ? 'roleTwo-left-m-100' : ''}`}>
      {isMobileView && (
        <Link to="#" className="closeIcon"> 
          <img 
            src={Close}
            alt="Close"
            onClick={() => setSidebar(!isShowSidebar)}
            width={24}
          />
        </Link>
      )}
      
      <ul className={!isSuperAdminRole ? 'client_logo-ui' : ""}>
        <li className={!isSuperAdminRole ? "client_logo-li" : "admin_li"}>
          <Link to="/">
            <img
              src={
                !!isSuperAdminRole
                  ? assets.images.grayLogo
                  : assets.images.shotLogo
              }
              alt="Logo"
              width=""
              className={
                !!isSuperAdminRole ? "logo-image" : "client_logo-image"
              }
            />
          </Link>
        </li>
        {renderSideRoute(isSuperAdminRole ? getAdminUserSidebar(superAdminRoutes, permissions) : (isAgencyRole || isMarketerRole) ? agencyRoutes : userRoutes)}
      </ul>
    </div>
  );
};

export default Sidebar;
