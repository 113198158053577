import BaseService from "../../../Base";
import ApiUrls from "../../../ApiUrl";
import { Master } from "types/common/master";

export default class MannerService extends BaseService {
  async getManner(param: Master.BodyParamType = {}) {
    try {
      const response = await this.post(`${ApiUrls.manner}/list`, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async createManner(params: Master.AdminType) {
    try {
      const response = await this.post(`${ApiUrls.manner}/create`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateManner(params: Master.AdminType) {
    try {
      const response = await this.post(`${ApiUrls.manner}/update`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteManner(params: Master.DeleteParamType) {
    try {
      const response = await this.post(`${ApiUrls.manner}/delete`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
}
