import ApiUrls from "services/ApiUrl";
import BaseService from "services/Base";
import { Master } from "types/common/master";

export default class ContentTypeServices extends BaseService {

    async getContentType(param: Master.BodyParamType = {}) {
      try {
        const response = await this.post(`${ApiUrls.contentType}/list`, param);
        return response?.data;
      } catch (error) {
        throw error;
      }
    }

    async createContentType(param: Master.BodyParamType = {}) {
        try {
          const response = await this.post(`${ApiUrls.contentType}/create`, param);
          return response?.data;
        } catch (error) {
          throw error;
        }
      }

      async updateContentType(param: Master.BodyParamType = {}) {
        try {
          const response = await this.post(`${ApiUrls.contentType}/update`, param);
          return response?.data;
        } catch (error) {
          throw error;
        }
      }

      async deleteContentType(param: Master.BodyParamType = {}) {
        try {
          const response = await this.post(`${ApiUrls.contentType}/delete`, param);
          return response?.data;
        } catch (error) {
          throw error;
        }
      }

}