import BaseService from "../../Base";
import ApiUrls from "../../ApiUrl";
import { Master } from "types/common/master";

export default class BrandService extends BaseService {
  async getBrand(param: Master.BodyParamType) {
    try {
      const response = await this.post(`${ApiUrls.brand}/list`, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async createBrand(params: Master.AdminType) {
    try {
      const response = await this.post(`${ApiUrls.brand}/create`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateBrand(params: Master.AdminType) {
    try {
      const response = await this.post(`${ApiUrls.brand}/update`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteBrand(params: Master.DeleteParamType) {
    try {
      const response = await this.post(`${ApiUrls.brand}/delete`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
}
