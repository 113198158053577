import BaseService from "./Base";
import AuthService from "./AuthService";
import ToneService from "./common/master/admin/ToneService";
import LanguageService from "./common/master/admin/LanguageService";
import MannerService from "./common/master/admin/MannerService";
import EmotionContextService from "./common/master/admin/EmotionContextService";
import BussinessListService from "./admin/bussinessListService";
import CampaignService from "./client/CampaignService";
import NotificationService from "./common/NotificationService";
import UserService from "./admin/UserService";
import BrandService from "./common/master/BrandService";
import SpecialistService from "./common/master/SpecialistService";
import DivisionService from "./common/master/DivisionService";
import RolePermissionService from "./common/RolePermissionService";
import DashboardService from "./common/DashboardService";
import EventService from "./EventService";
import ContentTypeServices from "./common/master/admin/ContentTypeService";
import AiService from "./client/AiService";
import InformationTypeService from "./common/master/admin/InformationTypeService";
import ContentService from "./common/master/admin/ContentServices";
import ProductsService from "./common/master/admin/ProductsService";
import PaymentService from "./common/master/admin/PaymentService";
import OrderService from "./common/master/admin/OrderServices";


const authService = new AuthService();
const baseService = new BaseService();
const toneService = new ToneService();
const languageService = new LanguageService();
const mannerService = new MannerService();
const emotionContextService = new EmotionContextService();
const bussinessListService = new BussinessListService();
const campaignService = new CampaignService();
const aiService = new AiService();
const notificationService = new NotificationService();
const userService = new UserService();
const brandService = new BrandService();
const specialistService = new SpecialistService();
const divisionService = new DivisionService();
const rolePermissionService = new RolePermissionService();
const dashboardService = new DashboardService();
const eventService = new EventService();
const contentTypeService = new ContentTypeServices()
const informationTypeService = new InformationTypeService()
const contentServiceService = new ContentService()
const productService = new ProductsService()
const paymentService = new PaymentService()
const orderService = new OrderService()


export {
  authService,
  baseService,
  toneService,
  languageService,
  mannerService,
  emotionContextService,
  bussinessListService,
  campaignService,
  notificationService,
  userService,
  brandService,
  specialistService,
  divisionService,
  rolePermissionService,
  dashboardService,
  eventService,
  contentTypeService,
  aiService,
  informationTypeService,
  contentServiceService,
  productService,
  paymentService,
  orderService
};
