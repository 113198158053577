import BaseService from "../../../Base";
import ApiUrls from "../../../ApiUrl";
import { Master } from "types/common/master";

export default class LanguageService extends BaseService {
  async getLanguage(param: Master.BodyParamType = {}) {
    try {
      const response = await this.post(`${ApiUrls.language}/list`, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async createLanguage(params: Master.AdminType) {
    try {
      const response = await this.post(`${ApiUrls.language}/create`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateLanguage(params: Master.AdminType) {
    try {
      const response = await this.post(`${ApiUrls.language}/update`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async deleteLanguage(params: Master.DeleteParamType) {
    try {
      const response = await this.post(`${ApiUrls.language}/delete`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
}
