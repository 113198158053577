import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  clearAll,
  getFromStore,
  getToken,
  getUser,
  getUserPermissions,
  removeItem,
  setToken,
  setToStorage,
  setUser,
  setUserPermissions,
  storageKey,
} from "helpers/storage";
import { RootState } from "redux/store";
import { authService, rolePermissionService } from "services";
import BaseService from "services/Base";
import { Auth } from "types/Auth";

type AppState = {
  user: Auth.UserProfile | null;
  token?: string | null;
  isLoading?: boolean;
  isLoaded?: boolean;
  isShowSubscription?: boolean;
  dashboardSidebarClickCount?: number;
  isHCPRole?: boolean;
  isAdminRole?: boolean;
  isSuperAdminRole?: boolean;
  isHospitalRole?: boolean;
  isMarketerRole?: boolean;
  isAgencyRole?: boolean;
  isEditorRole?: boolean;
  isPublisherRole?: boolean;
  isAnalystRole?: boolean;
  isShowSuperAdminButton?: boolean;
  isShowSidebar: boolean;
  windowWidth: string | number | null;
  isMobileView: boolean;
  isTabView: boolean;
  permissions: any;
  permissionLoaded: boolean;
};

export const getUserFromStore = createAsyncThunk(
  "auth/getUserFromStore",
  async (param: any, {dispatch}) => {
    const user: any = await getUser();
    const token = await getToken();
    const isShowSubscription = await getFromStore(storageKey.showSubscription);
    const dashboardSidebarClickCount = await getFromStore(
      storageKey.dashboardCount
    );
    // const Roles = Auth.UserProfileRole;
    // const isAdminUser = (roleId: number) => [
    //   Roles.Admin,
    //   Roles.Analyst,
    //   Roles.Editor,
    //   Roles.Publisher,
    // ].includes(roleId);
  
    // if(isAdminUser(user?.roleId)) dispatch(getPermissionsFromStore({
    //   "filter": { "roleSlug": Auth.RoleIdBySlug[user?.roleId] }
    // }));
    return { user, token, isShowSubscription, dashboardSidebarClickCount };
  }
);

export const getPermissionsFromStore = createAsyncThunk(
  "auth/getPermissionsFromStore",
  async (param: any) => {
    return await rolePermissionService.userPermission(param, "list");
  }
);

export const logoutStore = createAsyncThunk("auth/logoutStore", async () => {
  await authService.signOutUser({});
  return await clearAll();
});

const initialState: AppState = {
  user: null,
  token: null,
  isLoading: false,
  isLoaded: false,
  isShowSubscription: false,
  dashboardSidebarClickCount: 0,
  isAdminRole: false,
  isSuperAdminRole: false,
  isHospitalRole: false,
  isHCPRole: false,
  isMarketerRole: false,
  isAgencyRole: false,
  isEditorRole: false,
  isAnalystRole: false,
  isPublisherRole: false,
  isShowSuperAdminButton: false,
  isShowSidebar: true,
  windowWidth: null,
  isMobileView: false,
  isTabView: false,
  permissions: [],
  permissionLoaded: false
};

export const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setAppState: (state, action: PayloadAction<any>) => {
      const { user, token, isRememberMe, isPublicLink = false } = action.payload;
      const Roles = Auth.UserProfileRole;
      state.user = user;
      state.token = token;
      state.isAdminRole = user?.roleId == Roles.Admin;
      state.isSuperAdminRole = [Roles.Admin, Roles.Editor, Roles.Analyst, Roles.Publisher].includes(user?.roleId);
      state.isHospitalRole = user?.roleId == Roles.Hospital;
      state.isMarketerRole = user?.roleId == Roles.Marketer;
      state.isAgencyRole = user?.roleId == Roles.Agency;
      state.isHCPRole = user?.roleId == Roles.Doctor;
      state.isEditorRole = user?.roleId == Roles.Editor;
      state.isAnalystRole = user?.roleId == Roles.Analyst;
      state.isPublisherRole = user?.roleId == Roles.Publisher;
      BaseService.setAuthToken(token);
      if (!!isRememberMe) {
        setToken(token);
        setUser(user);
      }else if(!isPublicLink) {
        removeItem(storageKey.token);
        removeItem(storageKey.user);
      }
    },
    updateUser: (state, action: PayloadAction<any>) => {
      state.user = action?.payload?.data;
      const user = getUser();
      if(!!user && !!action?.payload?.data) setUser(action?.payload?.data);
    },
    updateShowSubscription: (state, action: PayloadAction<any>) => {
      state.isShowSubscription = action?.payload;
      setToStorage(storageKey.showSubscription, action?.payload);
    },
    updateDashboardSidebarClickCount: (state, action: PayloadAction<any>) => {
      state.dashboardSidebarClickCount = action?.payload;
      setToStorage(storageKey.dashboardCount, action?.payload);
    },
    updateIsShowSuperAdmin: (state, action: PayloadAction<any>) => {
      state.isShowSuperAdminButton = action?.payload;
    },
    updateIsShowSidebar: (state, action: PayloadAction<any>) => {
      state.isShowSidebar = action?.payload?.show;
    },
    updateWindowWidth: (state, action: PayloadAction<any>) => {
      const width = action?.payload?.width;
      state.windowWidth = width;
      if(width) {
        state.isShowSidebar = width < 768 ? false : true;
        state.isMobileView = width < 768;
        state.isTabView = width < 991;
      }  
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = false;
      })
      .addCase(
        getUserFromStore.fulfilled,
        (state, action: PayloadAction<any>) => {
          const {
            user,
            token,
            isShowSubscription,
            dashboardSidebarClickCount,
          } = action.payload;
          const Roles = Auth.UserProfileRole;
          state.user = user;
          state.token = token;
          state.isAdminRole = user?.roleId == Roles.Admin;
          state.isSuperAdminRole = [Roles.Admin, Roles.Editor, Roles.Analyst, Roles.Publisher].includes(user?.roleId);
          state.isHospitalRole = user?.roleId == Roles.Hospital;
          state.isMarketerRole = user?.roleId == Roles.Marketer;
          state.isAgencyRole = user?.roleId == Roles.Agency;
          state.isHCPRole = user?.roleId == Roles.Doctor;
          state.isEditorRole = user?.roleId == Roles.Editor;
          state.isAnalystRole = user?.roleId == Roles.Analyst;
          state.isPublisherRole = user?.roleId == Roles.Publisher;
          state.isShowSubscription = isShowSubscription;
          state.dashboardSidebarClickCount = dashboardSidebarClickCount;
          BaseService.setAuthToken(state.token || "");
          state.isLoading = false;
          state.isLoaded = true;
          if(state.isSuperAdminRole) {
            state.permissions = getUserPermissions();
            state.permissionLoaded = true;
          }
        }
      )
      .addCase(getUserFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      });
    builder
      .addCase(logoutStore.fulfilled, (state, action) => {
        state.user = null;
        state.token = null;
        state.isSuperAdminRole = false;
        state.isHospitalRole = false;
        state.isMarketerRole = false;
        state.isAgencyRole = false;
        state.isHCPRole = false;
        state.dashboardSidebarClickCount = 0;
        state.isShowSubscription = false;
        state.isAdminRole = false;
        state.isAnalystRole = false;
        state.isEditorRole = false;
        state.isPublisherRole = false;
        state.isShowSuperAdminButton = false;
        state.isShowSidebar = true;
        state.windowWidth = null;
        clearAll();
        // BaseService.resetAxios();
        BaseService.setAuthToken("");
      })
      .addCase(logoutStore.rejected, (state, action) => {
        state.isLoading = false;
      });
      builder.addCase(getPermissionsFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.permissionLoaded = false;
      })
      .addCase(getPermissionsFromStore.fulfilled, (state, action) => {
        const { data } = action.payload;
        if(data[0]?.permissions[0]?.permissions) {
          state.permissions = data[0]?.permissions[0]?.permissions || [];
        }else{
          state.permissions = [];
        }
        setUserPermissions(state.permissions);
        state.isLoading = false;
        state.permissionLoaded = true;
      })
      .addCase(getPermissionsFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.permissionLoaded = true;
      })
  },
});

export const {
  setAppState,
  updateShowSubscription,
  updateDashboardSidebarClickCount,
  updateIsShowSuperAdmin,
  updateUser,
  updateIsShowSidebar,
  updateWindowWidth
} = appStateSlice.actions;

export default appStateSlice.reducer;
export const selectCurrentUser = (state: RootState) => state.appState.user;
export const selectCurrentToken = (state: RootState) => state.appState.token;
export const selectIsLoading = (state: RootState) => state.appState.isLoading;
export const selectIsLoaded = (state: RootState) => state.appState.isLoaded;
export const selectIsSuperAdminRole = (state: RootState) =>
  state.appState.isSuperAdminRole;
export const selectIsShowSubscription = (state: RootState) =>
  state.appState.isShowSubscription;
export const selectDashboardSidebarClickCount = (state: RootState) =>
  state.appState.dashboardSidebarClickCount;
export const selectIsHospitalRole = (state: RootState) =>
  state.appState.isHospitalRole;
export const selectIsHCPRole = (state: RootState) => state.appState.isHCPRole;
export const selectIsMarketerRole = (state: RootState) =>
  state.appState.isMarketerRole;
export const selectIsAgencyRole = (state: RootState) =>
  state.appState.isAgencyRole;
export const selectIsShowSuperAdminButton = (state: RootState) =>
  state.appState.isShowSuperAdminButton
export const selectIsAdminRole = (state: RootState) => state.appState.isAdminRole;  
export const selectIsShowSidebar = (state: RootState) => state.appState.isShowSidebar;  
export const selectWindowWidth = (state: RootState) => state.appState.windowWidth;
export const selectIsMobileView = (state: RootState) => state.appState.isMobileView;
export const selectPermissions = (state: RootState) => state.appState.permissions;
export const selectPermissionLoaded = (state: RootState) => state.appState.permissionLoaded;
export const selectIsTabView = (state: RootState) => state.appState.isTabView