import { get } from "lodash";
import BaseService from "./Base";
import apiUrls from "./ApiUrl";
import { EventAIHeadingRequest, EventTopics } from "types/Others";

class EventService extends BaseService {
  constructor() {
    super(process.env.REACT_APP_API_BASE_URL);
  }

  async getAIHeading(data: EventAIHeadingRequest): Promise<any> {
    try {
      const response = await this.post(`${apiUrls.pressRelease}/generate-headline-for-open-event`, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async savePREvent(data: EventTopics): Promise<any> {
    try {
      const response = await this.post(`${apiUrls.pressRelease}/create-for-open-event`, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }
}

export default EventService;
