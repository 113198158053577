import BaseService from "../Base";
import ApiUrls from "../ApiUrl";

export default class RolePermissionService extends BaseService {
  async userPermission(params = {}, type: "create" | "update" | "list" | "delete" = "list") {
    try {
      const response = await this.post(`${ApiUrls.userRolesAndPermission}/${type}`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

}
