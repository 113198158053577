import { createAsyncThunk } from "@reduxjs/toolkit";
import { Master } from "types/common/master";
import { setContentType } from "../campaignOprationalSlice";
import { contentTypeService } from "services";



export const getContentTypeFromStore = createAsyncThunk(
    "auth/getToneFromStore",
    async (param: Master.BodyParamType, { dispatch }) => {
        const res = await contentTypeService.getContentType(param)

        if (!!res?.data)
        await dispatch(
          setContentType({
            contentType: res?.data?.map((el: any) => ({
              ...el,
              title: `${el.title}${el.isEnabled ? "" : ` (Coming soon...)`}`
            })),
          })
        );
      return res;
    }
  );