import { get } from "lodash";
import { Auth } from "types/Auth";
import BaseService from "./Base";
import apiUrls from "./ApiUrl";

class AuthService extends BaseService {
  constructor() {
    super(process.env.REACT_APP_API_BASE_URL);
  }

  //   async getOtp(data: any, params = {}) {
  //     try {
  //       const response = await this.post(apiUrls.sendOTP, data, params);
  //       return get(response, "data.data", {});
  //     } catch (error) {
  //       throw error;
  //     }
  //   }

  //   async otpSignIn(data: Auth.SignInParams, params = {}) {
  //     try {
  //       const response = await this.post(apiUrls.login, data, params);
  //       return get(response, "data.data", {});
  //     } catch (error) {
  //       throw error;
  //     }
  //   }

  async signIn(data: Auth.SignInParams): Promise<Auth.SignInResponse> {
    try {
      const response = await this.post(apiUrls.login, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async sentOtpByHash(data: Auth.SendOtpByHash): Promise<Auth.HashOtpResponse> {
    try {
      const response = await this.post(apiUrls.sentOtpByHash, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async verifyOtpByHash(
    data: Auth.VerifyOtpByHash
  ): Promise<Auth.VerifyOtpResponse> {
    try {
      const response = await this.post(apiUrls.verifyOtpByHash, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async setNewPassword(
    data: Auth.SetNewPassword
  ): Promise<Auth.SetNewPasswordResponse> {
    try {
      const response = await this.post(apiUrls.setNewPassword, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async forgotPassword(
    data: Auth.ForgotPassword
  ): Promise<Auth.ForgotPasswordResponse> {
    try {
      const response = await this.post(apiUrls.forgotPassword, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async changePassword(
    data: Auth.ChangePassword
  ): Promise<Auth.ChangePasswordResponse> {
    try {
      const response = await this.post(apiUrls.changePassword, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async signInAsUser(data: Auth.LoginAsUser): Promise<Auth.SignInResponse> {
    try {
      const response = await this.post(apiUrls.loginAs, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async sentOtpByUserName(
    data: Auth.SendOtpByUserName
  ): Promise<Auth.SendOtpByUserNameResponse> {
    try {
      const response = await this.post(apiUrls.sentOtpByUserName, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async generateTemporaryToken(
    data: Auth.GenerateTemporaryTokenReq
  ): Promise<Auth.SignInResponse> {
    try {
      const response = await this.post(apiUrls.generateTempToken, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async getMeUser(): Promise<any> {
    try {
      const response = await this.post(apiUrls.getMe, {});
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async signOutUser(data = {}): Promise<any> {
    try {
      const response = await this.post(apiUrls.signOut, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  async signUpUser(data = {}): Promise<any> {
    try {
      const response = await this.post(apiUrls.register, data);
      return get(response, "data", {});
    } catch (e: any) {
      throw e?.response?.data;
    }
  }

  //   async verifyOTP(data = {}): Promise<Auth.SignInResponse> {
  //     try {
  //       const response = await this.post(apiUrls.verifyPassword, data);
  //       return get(response, "data", {});
  //     } catch (e: any) {
  //       throw e?.response?.data;
  //     }
  //   }
  //   async resetPassword(data = {}): Promise<any> {
  //     try {
  //       const response = await this.post(apiUrls.resetPassword, data);
  //       return get(response, "data", {});
  //     } catch (e: any) {
  //       throw e?.response?.data;
  //     }
  //   }

  //   async changePassword(data = {}): Promise<any> {
  //     try {
  //       const response = await this.post(apiUrls.changePassword, data);
  //       return get(response, "data", {});
  //     } catch (e: any) {
  //       throw e?.response?.data;
  //     }
  //   }

}

export default AuthService;
