export namespace Auth {
  export type SignInParams = {
    userName: string;
    password: string;
    isLoginWith: "otp" | "password" | "";
  };
  export enum UserProfileRole {
    Admin = 1,
    SubAdmin = 2,
    Doctor = 3,
    Hospital = 4,
    Marketer = 5,
    Agency = 6,
    Editor = 7,
    Publisher = 8,
    Analyst = 9,
  }
  export const RoleIdBySlug: any = {
    [UserProfileRole.Admin]: "super-admin",
    [UserProfileRole.SubAdmin]: "admin",
    [UserProfileRole.Doctor]: "healthcare-professional",
    [UserProfileRole.Hospital]: "hospital",
    [UserProfileRole.Marketer]: "marketer",
    [UserProfileRole.Agency]: "agency",
    [UserProfileRole.Editor]: "editor",
    [UserProfileRole.Publisher]: "publisher",
    [UserProfileRole.Analyst]: "analyst",
  }
  export type CreatedByProfile = {
    email: string;
    firstName: string;
    fullName: string;
    lastName: string;
    profileId: number;
    title: string;
    userId: number;
  };
  export type Email = {
    email: string;
    isPrimary: number;
    id?: number;
    userId?: number;
  };
  export type Phone = {
    phone: string;
    isPrimary: number;
    id?: number;
    userId?: number;
  };
  export type Profile = {
    academicTrackId: number;
    // academicTracks: AcademicTrack[]
    academicTrack: string;
    active: 1;
    isAccessDisabled?: boolean;
    isDefault?: boolean;
    actualEndDate: string;
    sisId: number;
    contractEndDate: string;
    createdAt: string;
    // currentMajor: CurrentMajor | number
    // currentMajorVersionId: CurrentMajorVersion | number
    currentTerm: any;
    customProfileId?: string;
    deletedAt: string;
    endDate: string;
    enrollmentStatus: string;
    enrollmentType: string;
    id: number;
    isExternshipCoordinator: number;
    isSiteSupervisor: number;
    locations?: any[];
    startDate: string;
    // state: Auth.UserProfileState
    title: string;
    type: string;
    updatedAt: string;
    userId: number;
    userMigratedId: number;
    enrollmentId: string;
    campus?: any;
    campuses?: any[];
    courses?: any[];
    departments?: any[];
    degreeLevel?: any;
    // departmentSubunits: DepartmentSubunit[]
    roleIds?: number[];
    roles?: any[];
    initialInquiryDate?: string;
    applicationCompletedDate?: string;
    acceptanceDate?: string;
    lastDayOfAttendance?: string;
    awardYear?: string;
    payingMethods?: any[];
    dependencyStatus?: string;
    startingTerm?: any;
    startingTermId?: string;
    applicantId?: string;
    cancelDate?: string;
    reasonForCancellation?: string;
    inquiryMessage?: string;
  };
  export type UserProfile = {
    userId: number | null;
    clientId?: number | null;
    roleId: number | null;
    firstName: string;
    middleName?: string;
    lastName?: string;
    dob?: string;
    gender?: string;
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    postal?: string;
    email: string;
    mobile?: string;
    status?: boolean;
    lastActiveOn?: string;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
    createdBy?: number;
    updatedBy?: string;
    deletedBy?: string;
    salutation?: string;
    specialization?: string;
    registrationNumber?: string;
    companyName?: string;
    companyType?: string;
    isMobileVerified?: boolean;
    isEmailVerified?: boolean;
    qualification?: string;
    description?: string;
    profilePhoto?: string;
  };
  export type SignInResponse = {
    success: boolean;
    message: string;
    token: string;
    userData?: UserProfile;
    data?: any;
  };

  export type SendOtpByHash = {
    hash: string;
    isVerify: string[];
  };

  export type LoginAsUser = {
    userId: Number;
  };

  export type VerifyOtpByHash = {
    hash: string;
    emailOtp: number;
    mobileOtp?: number;
  };

  export type HashOtpResponse = {
    success: boolean;
    message: string;
    data: any;
    date?: any;
  };

  export type VerifyOtpResponse = {
    success: boolean;
    message: string;
    data: any;
  };

  export type SetNewPassword = {
    hash: string;
    password: string;
  };

  export type ForgotPassword = {
    userName: string;
  };

  export type ChangePassword = {
    email: string;
    previousPassword: string;
    newPassword: string;
  };

  export type ForgotPasswordResponse = {
    success?: boolean;
    message?: string;
    data?: any;
  };

  export type SetNewPasswordResponse = {
    success: boolean;
    message: string;
    data: any;
  };

  export type SendOtpByUserName = {
    userName: string;
    isResend?: boolean; 
  };

  export type SendOtpByUserNameResponse = {
    success: boolean;
    message: string;
    status: number;
  };

  export type ChangePasswordResponse = {
    success: boolean;
    message: string;
    data: any;
  };

  export type GenerateTemporaryTokenReq = {
    hash: string;
  };
}
