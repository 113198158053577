import Label from 'components/label'
import React from 'react'
import { InputProps } from 'types/components/Form'

const Input = React.forwardRef(({
  type = "text",
  label = "",
  required = false,
  disabled = false,
  placeholder = "",
  name = "",
  value = "",
  containerStyle = null,
  inputStyle = null,
  error = null,
  showInfo=false,
  tooltipText="",
  autoComplete = 'off',
  anchorSelectClass="",
  onChange = () => null,
  onKeyDown = () => null,
  onBlur = () => null,
  onFocus = () => null
}: InputProps, ref: any) => {
  return (
    <div className={`form-floating ${containerStyle || ''}`}>
      <input
        ref={ref}
        type={type}
        className={`form-control ${inputStyle || ''}`}
        id={`id_${name}`}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        name={name}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        onFocus={onFocus}
        autoComplete={autoComplete}
      />
      <Label 
        title={label}
        showInfo={showInfo}
        tooltipText={tooltipText}
        anchorSelectClass={anchorSelectClass}
        required={required}
      />
      {!!error && <span className="inputError">{error}</span>}
    </div>
  )
});

export default Input