import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toastSuccess } from "helpers/toastHelper";
import { RootState } from "redux/store";
import { authService } from "services";
import { Auth } from "types/Auth";

export const sentOtpByHashFromStore = createAsyncThunk(
  "auth/sentOtpByHashFromStore",
  async (param: Auth.SendOtpByHash) => {
    return await authService.sentOtpByHash(param);
  }
);

export const verifyOtpByHashFromStore = createAsyncThunk(
  "auth/verifyOtpByHashFromStore",
  async (param: Auth.VerifyOtpByHash) => {
    return await authService.verifyOtpByHash(param);
  }
);

type HashOtpState = {
  mobileOtp: string;
  isEmailVerified: boolean;
  isMobileVerified: boolean;
  isOtpVerify: boolean;
  message: string;
  isLoading: boolean;
  isLoaded: boolean;
  error: string;
};

const initialState: HashOtpState = {
  mobileOtp: "",
  isEmailVerified: false,
  isMobileVerified: false,
  isOtpVerify: false,
  message: "",
  isLoading: true,
  isLoaded: false,
  error: ""
};

export const sentOtpByHashSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetLoader: (state, action?) => {
      state.isLoaded = false;
    },
    resetSentOtpByHash: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(sentOtpByHashFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
        state.error = "";
      })
      .addCase(sentOtpByHashFromStore.fulfilled, (state, action) => {
        let { message, data } = action?.payload;
        state.mobileOtp = data?.mobileOTP;
        state.isOtpVerify = !!data?.isEmailVerified && !!data?.isMobileVerified;
        state.isEmailVerified = data?.isEmailVerified || false;
        state.isMobileVerified = data?.isMobileVerified || false;
        state.message = message;
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(sentOtpByHashFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
        state.error = action?.error?.message || "Invalid link.";
      });
    
    builder
      .addCase(verifyOtpByHashFromStore.pending, (state, action) => {
        state.isOtpVerify = false;
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(verifyOtpByHashFromStore.fulfilled, (state, action) => {
        let { message, success } = action?.payload;
        toastSuccess(message);
        state.isOtpVerify = success;
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(verifyOtpByHashFromStore.rejected, (state, action) => {
        state.isOtpVerify = false;
        state.isLoading = false;
        state.isLoaded = true;
      });  
  },
});

export default sentOtpByHashSlice.reducer;
export const { resetSentOtpByHash, resetLoader } = sentOtpByHashSlice.actions;
export const selectMobileOtp = (state: RootState) =>
  state.sentOtpByHashState.mobileOtp;
export const selectOTPMessage = (state: RootState) =>
  state.sentOtpByHashState.message;
export const selectOTPEmailVerified = (state: RootState) =>
  state.sentOtpByHashState.isEmailVerified;
export const selectOTPMobileVerified = (state: RootState) =>
  state.sentOtpByHashState.isMobileVerified;    
export const selectIsVerified = (state: RootState) =>
  state.sentOtpByHashState.isOtpVerify;
export const selectOTPError = (state: RootState) =>
  state.sentOtpByHashState.error;  
export const selectOTPLoading = (state: RootState) =>
  state.sentOtpByHashState.isLoading;  