import React, { Fragment } from 'react'
import "./styles.scss";

const Overlay = ({ isOpen = false, children = null, onClose }: any) => {
  return (
    <Fragment>
      {isOpen && (
        <div className="overlay">
          <div className="overlay__background" onClick={onClose} />
          <div className="overlay__container" />
        </div>
      )}
    </Fragment>
  );
}

export default Overlay