import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { toneService } from "services";
import { MetaPagination } from "types/Others";
import { Master } from "types/common/master";
import { setTonesState } from "../campaignOprationalSlice";
import { toastSuccess } from "helpers/toastHelper";

export const getToneFromStore = createAsyncThunk(
  "auth/getToneFromStore",
  async (param: Master.BodyParamType, { dispatch }) => {
    const res = await toneService.getTone(param);
    if (!!res?.data)
      await dispatch(
        setTonesState({
          tones: res?.data?.map((item: any) => {
            item.isChecked = false;
            return item;
          }),
        })
      );
    return res;
  }
);
export const createToneFromStore = createAsyncThunk(
  "auth/createToneFromStore",
  async ({ createPayload, getPayload }: any, { dispatch }) => {
    const cr = await toneService.createTone(createPayload);
    const res = await dispatch(getToneFromStore(getPayload));
    return { message: cr?.data?.message, res };
  }
);

export const updateToneFromStore = createAsyncThunk(
  "auth/updateToneFromStore",
  async ({ updatePayload, getPayload }: any, { dispatch }) => {
    const ur = await toneService.updateTone(updatePayload);
    await dispatch(getToneFromStore(getPayload));
    return { message: ur?.message };
  }
);

export const deleteToneFromStore = createAsyncThunk(
  "auth/deleteToneFromStore",
  async ({ delPayload, getPayload }: any, { dispatch }) => {
    const dr = await toneService.deleteTone(delPayload);
    await dispatch(getToneFromStore(getPayload));
    return { message: dr?.message };
  }
);

type ToneInitialState = {
  toneList: any[];
  isLoading: boolean;
  isLoaded: boolean;
  meta: MetaPagination;
  total: number;
};

const initialState: ToneInitialState = {
  toneList: [],
  isLoading: true,
  isLoaded: false,
  meta: {},
  total: 0,
};

export const toneSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetLoader: (state, action?) => {
      state.isLoaded = false;
    },
    resetTone: () => initialState,
  },
  extraReducers: (builder) => {
    builder

      // GET ACTION
      .addCase(getToneFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(getToneFromStore.fulfilled, (state, action) => {
        const { data, meta, total } = action?.payload;
        state.toneList = data;
        state.meta = meta;
        state.total = total;
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(getToneFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // CREATE ACTION
      .addCase(createToneFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(createToneFromStore.fulfilled, (state, action) => {
        const { message, res } = action?.payload;
        toastSuccess(message);
        state.toneList = res?.payload?.data;
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(createToneFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // UPDATE ACTION
      .addCase(updateToneFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(updateToneFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(updateToneFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // DELETE ACTION
      .addCase(deleteToneFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(deleteToneFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(deleteToneFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      });
  },
});

export const { resetTone, resetLoader } = toneSlice.actions;

export default toneSlice.reducer;
export const selectTone = (state: RootState) => state.toneState;
export const selectTones = (state: RootState) => state.toneState.toneList;
