import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getToken, getUser, setOldToken, setOldUser } from "helpers/storage";
import { toastSuccess } from "helpers/toastHelper";
import { authService } from "services";
import { Auth } from "types/Auth";
import { setAppState, updateIsShowSuperAdmin } from "../appStateSlice";
import { RootState } from "redux/store";

export const loginAsUserFromStore = createAsyncThunk(
  "auth/loginAsUserFromStore",
  async (param: Auth.LoginAsUser, { dispatch }) => {
    let oldToken: any = await getToken();
    let oldUser: any = await getUser();

    const res = await authService.signInAsUser(param);
    let payload = {
      user: res?.data,
      token: res?.token,
      isRememberMe: true,
    };
    await dispatch(setAppState(payload));
    await dispatch(updateIsShowSuperAdmin(true));
    setOldToken(oldToken);
    setOldUser(oldUser);

    return { message: res.message };
  }
);

type LoginAsUserState = {
  isLoading: boolean;
  isLoaded: boolean;
};

const initialState: LoginAsUserState = {
  isLoading: true,
  isLoaded: false,
};

export const loginAsUserSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetLoginAsLoader: (state, action?) => {
      state.isLoaded = false;
    },
    resetLoginAsUser: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsUserFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = false;
      })
      .addCase(loginAsUserFromStore.fulfilled, (state, action) => {
        let { message } = action?.payload;
        toastSuccess("Login as client successfully");
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(loginAsUserFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = false;
      });
  },
});

export default loginAsUserSlice.reducer;
export const { resetLoginAsUser, resetLoginAsLoader } = loginAsUserSlice.actions;
export const selectLoginAsUserLoaded = (state: RootState) =>
  state.loginAsUserState.isLoaded;
