import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toastSuccess } from "helpers/toastHelper";
import { authService } from "services";
import { Auth } from "types/Auth";

export const sentOtpByUserNameFromStore = createAsyncThunk(
  "auth/sentOtpByUserNameFromStore",
  async (param: Auth.SendOtpByUserName) => {
    return await authService.sentOtpByUserName(param);
  }
);

type UserNameState = {
  isLoading: boolean;
  isLoaded: boolean;
};

const initialState: UserNameState = {
  isLoading: true,
  isLoaded: false,
};

export const sentOtpByUserNameSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetLoader: (state, action?) => {
      state.isLoaded = false;
    },
    resetSentOtpByUserName: () => initialState,
  },
  extraReducers: (builder) => {
    builder

      .addCase(sentOtpByUserNameFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(sentOtpByUserNameFromStore.fulfilled, (state, action) => {
        let { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(sentOtpByUserNameFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      });
  },
});

export default sentOtpByUserNameSlice.reducer;
export const { resetSentOtpByUserName, resetLoader } =
  sentOtpByUserNameSlice.actions;
