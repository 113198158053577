import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { notificationService } from "services";
import { MetaPagination } from "types/Others";
import { Master } from "types/common/master";
import { CampaignProps } from "types/users/Campaign";

export const getPublisingMediasFromStore = createAsyncThunk(
  "auth/getPublisingMediasFromStore",
  async (param: Master.BodyParamType = {}, { dispatch }) => {
    return await notificationService.getPublishingMedias(param);
  }
);

type CommonMasterState = {
  publisingMedias: CampaignProps.PublishMediaProps[];
  isLoading: boolean;
  isLoaded: boolean;
  meta: MetaPagination;
  total: number;
};

const initialState: CommonMasterState = {
  publisingMedias: [],
  isLoading: true,
  isLoaded: false,
  meta: {},
  total: 0,
};

export const commonMasterSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetCMLoader: (state, action?) => {
      state.isLoaded = false;
    },
    updatePublishingMedias: (state, action) => {
      state.publisingMedias = action.payload?.data;
    },
    resetCommonMaster: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // GET ACTION
      .addCase(getPublisingMediasFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(getPublisingMediasFromStore.fulfilled, (state, action) => {
        const { data} = action.payload;
        state.publisingMedias = data?.map((item: any) => {item.isChecked = false; return item;});
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(getPublisingMediasFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })
  },
});

export const { resetCommonMaster, resetCMLoader, updatePublishingMedias } = commonMasterSlice.actions;

export default commonMasterSlice.reducer;
export const selectPublisingMedias = (state: RootState) => state.commonMasterState.publisingMedias;
export const selectPMLoading = (state: RootState) => state.commonMasterState.isLoading;