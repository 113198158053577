export type StorageKeys =
  | "user"
  | "token"
  | "dashboardCount"
  | "showSubscription";

export type StorageKeysType = {
  user: string;
  oldUser:string;
  oldToken:string;
  token: string;
  dashboardCount: string;
  showSubscription: string;
  permissions: string;
};

export type Sort = {
  orderDir?: string;
  orderBy?: string;
};

export type Range = {
  page?: number;
  pageSize?: number;
};

export type MetaPagination = Range & {
  pageCount?: number;
};

export type SelectOptions = {
  title: string;
  value: string;
};

export type CreateResponseType = {
  success: boolean;
  message: string;
  data: any[];
};

export enum ApprovalStatus {
  PROCESSING = "processing",
	PENDING = "pending",
	PENDING_REVIEW = "pending-review",
	PENDING_APPROVAL = "pending-approval",
	APPROVED = "approved",
  REJECTED = "rejected",
  ARCHIVED = "archived",
  PUBLISHED = "published"
}

export type ApprovalStatusTypes = ApprovalStatus.PROCESSING | ApprovalStatus.PENDING | ApprovalStatus.PENDING_REVIEW | ApprovalStatus.PENDING_APPROVAL | ApprovalStatus.APPROVED | ApprovalStatus.REJECTED | ApprovalStatus.ARCHIVED | ApprovalStatus.PUBLISHED;

export type ParamType = {
  filter?: FilterType;
  sort?: Sort;
  range?: Range;
};

export type FilterType = {
  roleId?:number[] | number;
  userId?:number[] | number;
  approvalStatus?: "approved" | "pending";
  campaignId?: number[];
  createdBy?: number[];
  isRead?: boolean;
  notificationId?: number;
  title?: string;
  search?: string;
  pressReleaseId?: number | string;
  subCampaignId?: number | string;
  isCurrentRunning?: boolean;
};

export type PageWiseRouteType = "user" | "business-client" | "campaign" | "press-release" | "notification" | "client" | ""

export type ActionPayload = {
  data?: any;
  loading?: boolean;
}

export type IconProps = {
  onClick?: (event?: any) => void;
  className?: any;
  width?: number;
  height?: number;
  isWhite?: boolean;
  tooltipMsg?: string;
  src?: string;
}

export type StatusTypes = ApprovalStatus.PENDING | ApprovalStatus.APPROVED | ApprovalStatus.ARCHIVED | ApprovalStatus.PUBLISHED | "";

export type StatusOptions = {
  title: string;
  type: StatusTypes;
};

export const ApprovalStatusColors: any = {
  [ApprovalStatus.PROCESSING]: "process-chip",
  [ApprovalStatus.PENDING]: "pending-chip",
  [ApprovalStatus.PENDING_REVIEW]: "pending-review-chip",
  [ApprovalStatus.PENDING_APPROVAL]: "pending-approval-chip",
  [ApprovalStatus.APPROVED]: "approval-chip",
  [ApprovalStatus.REJECTED]: "rejected-chip",
  [ApprovalStatus.ARCHIVED]: "archive-chip",
  [ApprovalStatus.PUBLISHED]: "published-chip",
  "Processing": "process-chip",
  "Pending": "pending-chip",
  "Pending Review": "pending-review-chip",
  "Pending Approval": "pending-approval-chip",
  "Approved": "approval-chip",
  "Rejected": "rejected-chip",
  "Archived": "archive-chip",
  "Published": "published-chip"
}

export type FileUpload = {
  file: any[]
}

export type RoleType = "admin" | "client";

export type RoleWithParam = {
  paramType: ParamType,
  roleType: RoleType
}

export type EventUserDetail = {
  salutation?: string;
  firstName: string;
  lastName?: string;
  email: string;
  mobile: string;
  specialization?: string;
}
export type EventTopics = {
  campaignId: number;
  topic?: string;
  brief: string;
  headline: string;
  userDetails: EventUserDetail;
}

export type EventComponentProps = {
  stepLength: number;
  index: number;
  handleIncrement: (value: any) => void;
}

export type EventAIHeadingRequest = {
  brief: string;
}
export enum PaymentStatus{
	SUCCESS ="Success",
	PAYMENTINITIATED="Payment Initiated",
	FAILURE ="Failure", 
	NOTFOUND ="Not Found",
	TIMEOUT="Timeout",
	ORDERCREATED="Order Created"
}
