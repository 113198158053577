import React from 'react'
import "./styles.scss";

interface Props {
  labelText?: String;
  type?: 'radio' | 'checkbox';
  name?: string;
  id?: string;
  value?: string;
  checked?: boolean;
  onChange?: (event: any) => void;
}

function Chip({
  labelText = "",
  type = "radio",
  name = "",
  id = "",
  value = "",
  checked = false,
  onChange = () => null
}: Props) {
  return (
    <div className='inputCheckContainer'>
      {!!value ? (
        <input
          type={type}
          id={id}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
        />
      ) : (
        <input
          type={type}
          id={id}
          name={name}
          // value={value}
          // checked={checked}
          // onChange={onChange}
        />
      )}
      <label htmlFor={id}>{labelText}</label>
    </div>
  )
}

export default Chip;

