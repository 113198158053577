import BaseService from "../../../Base";
import ApiUrls from "../../../ApiUrl";
import { Master } from "types/common/master";

export default class EmotionContextService extends BaseService {

  async getEmotionContext(param: Master.BodyParamType = {}) {
    try {
      const response = await this.post(`${ApiUrls.emotionContext}/list`, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  

  async createEmotionContext(params: Master.AdminType) {
    try {
      const response = await this.post(`${ApiUrls.emotionContext}/create`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async updateEmotionContext(params: Master.AdminType) {
    try {
      const response = await this.post(`${ApiUrls.emotionContext}/update`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async deleteEmotionContext(params: Master.DeleteParamType) {
    try {
      const response = await this.post(`${ApiUrls.emotionContext}/delete`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
}
