import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { eventService } from "services";
import { EventUserDetail, EventTopics, EventAIHeadingRequest } from "types/Others";

export type EventContentDetails = {
  topic: string;
  brief: string;
}
export type EventAIHeading = {
  heading: string;
}
export type NotificationState = {
  isLoading: boolean;
  isLoaded: boolean;
  contentDetails: EventContentDetails | null;
  aiHeading: EventAIHeading | null;
  basicDetails: EventUserDetail;
  aiHeadings: any[];
  formId: number;
  campaignFormRequest: EventTopics
};

const initCampaignRequest: EventTopics = {
    "campaignId": 1,
    "topic": "",
    "brief": "",
    "headline": "",
    "userDetails": {
        "salutation": "dr.",
        "firstName": "",
        "lastName": "",
        "email": "",
        "mobile": "",
        "specialization": ""
    }
}

export const initBasicDetails: EventUserDetail = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  salutation: "dr.",
  specialization: ""
}

export const initContentDetails: EventContentDetails = {
  brief: "",
  topic: ""
}

export const initAIHeading: EventAIHeading = {
  heading: ""
}


export const getEventAIHeadlineStore = createAsyncThunk(
  "auth/getEventAIHeadlineStore",
  async (param: EventAIHeadingRequest) => {
    return await eventService.getAIHeading(param);
  }
);

const initialState: NotificationState = {
  isLoading: false,
  isLoaded: false,
  contentDetails: initContentDetails,
  aiHeading: initAIHeading,
  basicDetails: initBasicDetails,
  aiHeadings: [],
  formId: 1,
  campaignFormRequest: initCampaignRequest
};

export const eventSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setEventFormIdState: (state, action: PayloadAction<any>) => {
      const { formId } = action.payload;
      state.formId = formId;
    },
    updateEventForm: (state, action: PayloadAction<any>) => {
      const { data } = action.payload;
      state.campaignFormRequest = data;
    },
    resetEventLoader: (state, action?) => {
      state.isLoaded = false;
    },
    updateEventLoading: (state, action?) => {
      state.isLoading = action?.payload.loading;
    },
    resetEvent: () => initialState,
  },
  extraReducers: (builder) => {
    builder
    .addCase(getEventAIHeadlineStore.pending, (state, action) => {
      state.aiHeadings = [];
      state.isLoading = true;
    })
    .addCase(getEventAIHeadlineStore.fulfilled, (state, action) => {
      const { data } = action.payload;
      state.aiHeadings = data;
      state.isLoading = false;
    }).addCase(getEventAIHeadlineStore.rejected, (state, action) => {
      state.isLoading = false;
    })            
  },
});

export const {
  setEventFormIdState,
  updateEventForm,
  resetEventLoader,
  resetEvent,
  updateEventLoading
} = eventSlice.actions;

export default eventSlice.reducer;
export const selectEventState = (state: RootState) => state.eventState;
export const selectEventFormId = (state: RootState) => state.eventState.formId;
export const selectEventLoading = (state: RootState) => state.eventState.isLoading;
export const selectEventAIHeadings = (state: RootState) => state.eventState.aiHeadings;
export const selectEventCampaignFormRequest = (state: RootState) => state.eventState.campaignFormRequest;
