import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { selectContentType } from "redux/features/campaignOprationalSlice";
import { getContentTypeFromStore } from "redux/features/master/contentTypeSlice";
import { useAppDispatch, useTypedSelector } from "redux/store";
import { orderService } from "services";
import { PaymentStatus } from "types/Others";
import "./styles.scss";

function ContentType(props: any) {
  return (
    <div>
      <h3 className="text-center mt-5">
        You can choose among these to create a new press release.
      </h3>
      <div className="submitpress">
        {props.contentTypes?.map((data: any, id: number) => (
          <div key={id}  className="row-12 ">
            <div
              className="cardView col-3"
              onClick={() => {
                if (data.isEnabled) props.onChooseContent(data);
              }}
              style={{backgroundColor:data.isEnabled?"":"#DCD2CC",color:data.isEnabled?"":"#393633"}}
            >
              {data.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
const PaymentRedirect = () => {
  const location = useLocation();
  const orderId = new URLSearchParams(location.search).get("orderId");
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState<{
    message: string;
    paymentStatus: PaymentStatus;
  } | null>(null);
  const dispatch = useAppDispatch();
  const contentTypes = useTypedSelector(selectContentType);

  const setMasterStates = () => {
    let payload = {
      filter: {
        status: true,
      },
    };
    dispatch(getContentTypeFromStore(payload));
  };

  async function getOrderDetails() {
    const orderDetails = await orderService.getOrderData({ orderId });
    setOrderDetails(orderDetails.data);
  }

  useEffect(() => {
    setMasterStates();
    if (orderId) getOrderDetails();
  }, [orderId]);

  const renderContent = () => {
    if (!orderDetails) return null;
    const { message, paymentStatus } = orderDetails;

    switch (paymentStatus) {
      case PaymentStatus.SUCCESS:
        return (
          <div className="alert alert-success">
            <h3 className="text-center">Payment Successful</h3>
            <p className="text-center">{message}</p>
            <p className="text-center">
              Thank you for your purchase! Your order is being processed.
            </p>
          </div>
        );
      case PaymentStatus.PAYMENTINITIATED:
        return (
          <div className="alert alert-info">
            <h3 className="text-center">Payment Initiated</h3>
            <p className="text-center">{message}</p>
            <p className="text-center">
              We have received your payment initiation. Please wait while we
              complete the process.
            </p>
          </div>
        );
      case PaymentStatus.FAILURE:
        return (
          <div className="alert alert-danger">
            <h3 className="text-center">Payment Failed</h3>
            <p className="text-center">{message}</p>
            <p className="text-center">
              Unfortunately, the payment was unsuccessful. Please try again or
              contact support.
            </p>
          </div>
        );
      case PaymentStatus.NOTFOUND:
        return (
          <div className="alert alert-warning">
            <h3 className="text-center">Order Not Found</h3>
            <p className="text-center">{message}</p>
            <p className="text-center">
              We could not find the order details. Please check the order ID or
              contact support.
            </p>
          </div>
        );
      case PaymentStatus.TIMEOUT:
        return (
          <div className="alert alert-warning">
            <h3 className="text-center">Payment Timeout</h3>
            <p className="text-center">{message}</p>
            <p className="text-center">
              The payment process timed out. Please try again later.
            </p>
          </div>
        );
      case PaymentStatus.ORDERCREATED:
        return (
          <div className="alert alert-success">
          <h3 className="text-center">Press Release Created</h3>
          <p className="text-center">Your Press Release has been created</p>
        </div>
        );
      default:
        return (
          <div className="alert alert-secondary">
            <h3 className="text-center">Unknown Status</h3>
            <p className="text-center">
              The payment status could not be determined.
            </p>
          </div>
        );
    }
  };

  return (
    <div className="conta iner">
      <div className="my-5">
        {renderContent()}
        <div className="text-center my-3">
          <ContentType
            contentTypes={contentTypes}
            onChooseContent={(data: any) => {
              navigate(
                `/manage-content/add-new?contentTypeId=${data.contentTypeId}`
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentRedirect;
