import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux/store";
import { mannerService } from "services";
import { MetaPagination } from "types/Others";
import { Master } from "types/common/master";
import { setMannerState } from "../campaignOprationalSlice";
import { toastSuccess } from "helpers/toastHelper";

export const getMannerFromStore = createAsyncThunk(
  "auth/getMannerFromStore",
  async (param: Master.BodyParamType = {}, { dispatch }) => {
    const res = await mannerService.getManner(param);
    if (!!res?.data)
      await dispatch(
        setMannerState({
          manner: res?.data?.map((item: any) => {
            item.isChecked = false;
            return item;
          }),
        })
      );
    return res;
  }
);
export const createMannerFromStore = createAsyncThunk(
  "auth/createMannerFromStore",
  async ({ createPayload, getPayload }: any, { dispatch }) => {
    const cr = await mannerService.createManner(createPayload);
    const res = await dispatch(getMannerFromStore(getPayload));
    return { message: cr?.message, res };
  }
);

export const updateMannerFromStore = createAsyncThunk(
  "auth/updateMannerFromStore",
  async ({ updatePayload, getPayload }: any, { dispatch }) => {
    const ur = await mannerService.updateManner(updatePayload);
    await dispatch(getMannerFromStore(getPayload));
    return { message: ur?.message };
  }
);

export const deleteMannerFromStore = createAsyncThunk(
  "auth/deleteMannerFromStore",
  async ({ delPayload, getPayload }: any, { dispatch }) => {
    const dr = await mannerService.deleteManner(delPayload);
    await dispatch(getMannerFromStore(getPayload));
    return { message: dr?.message };
  }
);

type MannerState = {
  mannerList: any[];
  isLoading: boolean;
  isLoaded: boolean;
  meta: MetaPagination;
  total: number;
};

const initialState: MannerState = {
  mannerList: [],
  isLoading: true,
  isLoaded: false,
  meta: {},
  total: 0,
};

export const mannerSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetLoader: (state, action?) => {
      state.isLoaded = false;
    },
    resetManner: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // .addCase(getMannerFromStore.fulfilled, (state, action) => {
      //   const { data, meta, total } = action.payload;
      //   state.mannerList = data;
      //   state.meta = meta;
      //   state.total = total;
      //   state.isLoading = false;
      //   state.isLoaded = true;
      // })
      // .addCase(getMannerFromStore.rejected, (state, action) => {
      //   state.total = 0;
      //   state.isLoading = false;
      //   state.isLoaded = true;
      //   state.mannerList = [];
      // });

      // GET ACTION
      .addCase(getMannerFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(getMannerFromStore.fulfilled, (state, action) => {
        const { data, meta, total } = action.payload;
        state.mannerList = data;
        state.meta = meta;
        state.total = total;
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(getMannerFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // CREATE ACTION

      .addCase(createMannerFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(createMannerFromStore.fulfilled, (state, action) => {
        const { message, res } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(createMannerFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // UPDATE ACTION

      .addCase(updateMannerFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(updateMannerFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(updateMannerFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      })

      // DELETE ACTION

      .addCase(deleteMannerFromStore.pending, (state, action) => {
        state.isLoading = true;
        state.isLoaded = true;
      })
      .addCase(deleteMannerFromStore.fulfilled, (state, action) => {
        const { message } = action?.payload;
        toastSuccess(message);
        state.isLoading = false;
        state.isLoaded = true;
      })
      .addCase(deleteMannerFromStore.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoaded = true;
      });
  },
});

export const { resetManner, resetLoader } = mannerSlice.actions;

export default mannerSlice.reducer;
export const selectManner = (state: RootState) => state.mannerState;
export const selectManners = (state: RootState) => state.mannerState;
