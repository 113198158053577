import { Auth } from "../types/Auth";
import { StorageKeysType } from "../types/Others";
import secureLocalStorage from "react-secure-storage";

export const storageKey: StorageKeysType = {
  user: "user@theHealthPresso",
  token: "token@theHealthPresso",
  oldToken: "oldToken@theHealthPresso",
  oldUser: "oldUser@theHealthPresso",
  dashboardCount: "dashboardCount@theHealthPresso",
  showSubscription: "showSubscription@theHealthPresso",
  permissions: "acc@theHealthPresso"
};

export function setUser(data: Auth.UserProfile) {
  return secureLocalStorage.setItem(storageKey.user, data);
}

export function getUser() {
  return secureLocalStorage.getItem(storageKey.user);
}

export function setToken(data: string) {
  return secureLocalStorage.setItem(storageKey.token, data);
}

export function getToken() {
  return secureLocalStorage.getItem(storageKey.token);
}

export function setOldToken(data: string) {
  return secureLocalStorage.setItem(storageKey.oldToken, data);
}

export function getOldToken() {
  return secureLocalStorage.getItem(storageKey.oldToken);
}

export function setOldUser(data: Auth.UserProfile) {
  return secureLocalStorage.setItem(storageKey.oldUser, data);
}

export function getOldUser() {
  return secureLocalStorage.getItem(storageKey.oldUser);
}

export function setUserPermissions(data: any) {
  return secureLocalStorage.setItem(storageKey.permissions, data);
}

export function getUserPermissions() {
  return secureLocalStorage.getItem(storageKey.permissions);
}

export function setToStorage(key: string, data: any) {
  return secureLocalStorage.setItem(key, data);
}

export function getFromStore(key: string) {
  return secureLocalStorage.getItem(key);
}

export function clearAll() {
  secureLocalStorage.clear();
}

export function removeItem(key: string) {
  secureLocalStorage.removeItem(key);
}
