import { Delete, MarkEmailReadIcon, MarkEmailUnReadIcon, Search } from "assets";
import { PageContainer, PaginationComponent } from "components";
import "./styles.scss";
import { useAppDispatch, useTypedSelector } from "redux/store";
import { useCallback, useEffect, useState } from "react";
import {
  deleteNotificationsFromStore,
  getNotificationsFromStore,
  readNotificationsFromStore,
  selectNotificationMeta,
  selectNotificationSearch,
  selectNotificationTotal,
  selectNotifications,
  unreadNotificationsFromStore,
  updatePagination,
} from "redux/features/notificationSlice";
import { getDateFromNow, textCapitalize } from "helpers/common";
import { NotificationType } from "types/common/notification";
import { useNavigate } from "react-router-dom";
import { MetaPagination } from "types/Others";
import { Tooltip } from "react-tooltip";
import Confirmation from "components/common/confirmation";
import NoDataComponent from "components/nodata";
import { useAuth } from "hooks/useAuth";

const Notifications = () => {
  const notifications = useTypedSelector(selectNotifications);
  const notificationTotal: number = useTypedSelector(selectNotificationTotal);
  const paginationMeta: MetaPagination = useTypedSelector(
    selectNotificationMeta
  );
  const [tab, setTab] = useState<"all" | "read" | "unread">("all");
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [notificationIds, setNotificationIds] = useState<number[]>([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [readConfirmation, setReadConfirmation] = useState<boolean>(false);
  const [unreadConfirmation, setUnreadConfirmation] = useState<boolean>(false);
  const notificationSearch = useTypedSelector(selectNotificationSearch);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    getNotifiactionList();
  }, [tab, paginationMeta?.page, notificationSearch]);

  const getNotifiactionList = () => {
    const payload: any = {
      filter: {
        search: notificationSearch || "",
      },
      range: {
        page: paginationMeta?.page || 1,
        pageSize: paginationMeta?.pageSize || 10,
      },
    };
    if (tab != "all") {
      payload["filter"]["isRead"] = tab === "read";
    }
    dispatch(getNotificationsFromStore(payload));
  };

  const onClickNotification = async (
    item: NotificationType.NotificationDetail
  ) => {
    if (item?.notificationId) {
      if (!item?.isRead) {
        const payload: NotificationType.UpdateParam = {
          notificationId: [item?.notificationId],
          pagination: {
            page: paginationMeta?.page || 1,
            pageSize: paginationMeta?.pageSize || 10,
          },
        };
        await dispatch(readNotificationsFromStore(payload));
      }
      if (item?.moduleSlug === "press-release") {
        navigate(`/press-releases/view/${item?.referenceId}`);
      }
    }
  };

  const notificationCallHandler = async (
    type: "delete" | "read" | "unread"
  ) => {
    if (!notificationIds?.length) return;
    const payload: NotificationType.UpdateParam = {
      notificationId: notificationIds,
      pagination: {
        page: paginationMeta?.page || 1,
        pageSize: paginationMeta?.pageSize || 10,
      },
    };
    if (type === "delete") {
      await dispatch(deleteNotificationsFromStore(payload));
      setDeleteConfirmation(false);
    } else if (type === "read") {
      await dispatch(readNotificationsFromStore(payload));
      setReadConfirmation(false);
    } else if (type === "unread") {
      await dispatch(unreadNotificationsFromStore(payload));
      setUnreadConfirmation(false);
    }
    getNotifiactionList();
    setNotificationIds([]);
    setCheckedAll(false);
  };

  const onClickAllNotification = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedAll(e?.target?.checked);
    setNotificationIds(
      notifications?.map(
        (item: NotificationType.NotificationDetail) => item.notificationId
      )
    );
  };

  const onClickNotificationCheck = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: NotificationType.NotificationDetail
  ) => {
    const checked = e?.target?.checked;
    if (item.notificationId) {
      if (!!checked)
        setNotificationIds([
          ...new Set([...notificationIds, item.notificationId]),
        ]);
      else if (!checked)
        setNotificationIds(
          notificationIds.filter((id: number) => id !== item.notificationId)
        );
    }
  };

  const matchNotification = useCallback(
    (id: number) => notificationIds?.includes(id),
    [notificationIds.length]
  );

  return (
    <>
      <PageContainer>
        <div className="d-flex justify-content-between mt-3 header">
          <h4>Notifications</h4>
        </div>
        <div className="mb-5">
          <div className="notificationContainer shadow">
            <div className="firstHeader consent-area">
              <input
                disabled={false}
                type="checkbox"
                checked={checkedAll}
                onChange={(e) => onClickAllNotification(e)}
              />
              {(checkedAll || !!notificationIds?.length) && (
                <>
                  <span
                    onClick={() => setDeleteConfirmation(true)}
                    className="filterOption"
                  >
                    <Tooltip
                      anchorSelect=".del-notify"
                      place="bottom"
                      className="notifyTooltip"
                    >
                      Delete notification
                      {notificationIds?.length > 1 ? "s" : ""}
                    </Tooltip>
                    <img
                      src={Delete}
                      className="del-notify"
                      alt="Delete"
                      width="18"
                    ></img>
                  </span>
                  <span
                    onClick={() => {
                      setReadConfirmation(true);
                    }}
                    className="filterOption"
                  >
                    <Tooltip
                      anchorSelect=".mark-as-read-notify"
                      place="bottom"
                      className="notifyTooltip"
                    >
                      Mark notifiaction{notificationIds?.length > 1 ? "s" : ""}{" "}
                      as read
                    </Tooltip>
                    <img
                      src={MarkEmailReadIcon}
                      className="mark-as-read-notify"
                      alt="MarkEmailReadIcon"
                      width="22"
                    ></img>
                  </span>
                  <span
                    onClick={() => setUnreadConfirmation(true)}
                    className="filterOption"
                  >
                    <Tooltip
                      anchorSelect=".mark-as-unread-notify"
                      place="bottom"
                      className="notifyTooltip"
                    >
                      Mark notifiaction{notificationIds?.length > 1 ? "s" : ""}{" "}
                      as unread
                    </Tooltip>
                    <img
                      src={MarkEmailUnReadIcon}
                      className="mark-as-unread-notify"
                      alt="MarkEmailUnReadIcon"
                      width="22"
                    ></img>
                  </span>
                </>
              )}
            </div>
            <div className="secondaryHeader">
              <div className="filterContainer">
                <span
                  onClick={() => setTab("all")}
                  className={`filterOption ${tab === "all" ? "boldText" : ""} `}
                >
                  All
                </span>
                <span
                  onClick={() => setTab("unread")}
                  className={`filterOption ${
                    tab === "unread" ? "boldText" : ""
                  } `}
                >
                  Unread
                </span>
                <span
                  onClick={() => setTab("read")}
                  className={`filterOption ${
                    tab === "read" ? "boldText" : ""
                  } `}
                >
                  Read
                </span>
              </div>
            </div>
            <div className="notificationListContainer consent-area">
              {!!notifications?.length
                ? notifications?.map(
                    (
                      item: NotificationType.NotificationDetail,
                      index: number
                    ) => {
                      return (
                        <div
                          key={`notification_${index}`}
                          className={`notificationItem  ${
                            !item?.isRead ? "readItem" : ""
                          }`}
                        >
                          <input
                            disabled={false}
                            type="checkbox"
                            value={item?.notificationId}
                            checked={matchNotification(item.notificationId)}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => onClickNotificationCheck(e, item)}
                          />
                          <div
                            className="d-flex"
                            onClick={() => onClickNotification(item)}
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src={item?.logo}
                                alt="PR"
                                width={50}
                                height={50}
                                className="itemIcon"
                              />
                            </div>
                            <div className="notificationInfo">
                              <div className="notificationInfoHeader">
                                <h5 className="notificationText">
                                  {" "}
                                  By{" "}
                                  {user?.userId == item?.createdBy ? (
                                    <span>You</span>
                                  ) : (
                                    <span>
                                      {textCapitalize(
                                        item?.createdByDetails?.salutation
                                      ) || ""}{" "}
                                      {item?.createdByDetails?.firstName || ""}{" "}
                                      {item?.createdByDetails?.lastName || ""}
                                    </span>
                                  )}
                                </h5>
                                <span className="dot"></span>
                                <p className="time">
                                  {getDateFromNow(item?.createdAt)}
                                </p>
                              </div>
                              <div className="mt-1">{item?.title}</div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )
                : !notifications?.length && (
                    <NoDataComponent message="No notification found!" />
                  )}
            </div>
          </div>
          <div className="mt-4 flexEnd">
            <PaginationComponent
              currentPage={paginationMeta?.page}
              pageSize={paginationMeta?.pageSize}
              totalCount={notificationTotal}
              onChangePage={(page: number) => {
                dispatch(updatePagination({ page }));
              }}
            />
          </div>
        </div>
      </PageContainer>
      {(deleteConfirmation || readConfirmation || unreadConfirmation) && (
        <Confirmation
          show={deleteConfirmation || readConfirmation || unreadConfirmation}
          onHide={() => {
            setDeleteConfirmation(false);
            setReadConfirmation(false);
            setUnreadConfirmation(false);
          }}
          onConfirmation={() => {
            if (deleteConfirmation) notificationCallHandler("delete");
            if (readConfirmation) notificationCallHandler("read");
            if (unreadConfirmation) notificationCallHandler("unread");
          }}
          title={
            deleteConfirmation
              ? "Delete Confirmation"
              : readConfirmation
              ? "Read Confirmation"
              : unreadConfirmation && "Unread Confirmation"
          }
          description={`Are you sure you want to ${
            deleteConfirmation
              ? "delete"
              : readConfirmation
              ? "read"
              : unreadConfirmation && "unread"
          } notification${notificationIds?.length > 1 ? "s" : ""}!`}
          actionBtnName="Yes"
          actionBtnColor="white"
          actionBtnBgColor="#00b8c2"
        />
      )}
    </>
  );
};

export default Notifications;
