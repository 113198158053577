import ApiUrls from "services/ApiUrl";
import BaseService from "services/Base";
import { Master } from "types/common/master";

export default class OrderService extends BaseService {

    async getOrderData(param = {}) {
      try {
        const response = await this.post(`${ApiUrls.orderService}/listPaymentStatus`, param);
        return response?.data;
      } catch (error) {
        throw error;
      }
    }
}