import { bell } from 'assets';
import { IconProps } from 'types/Others';

const BellIcon = ({
  width = 24,
  height = 24
}: IconProps) => {
  return (
      <img
        src={bell}
        alt=""
        width={width}
        height={height}
      />
  )
}

export default BellIcon