import { InfoIcon } from "assets";
import { Tooltip } from "react-tooltip";

type Props = {
  title: string;
  required?: boolean;
  sebLabel?: string;
  className?: string;
  showInfo?: boolean;
  tooltipText?: string;
  anchorSelectClass?:string
};

const Label = ({
  title = "",
  required = false,
  sebLabel = "",
  className,
  showInfo = false,
  tooltipText = "",
  anchorSelectClass=""
}: Props) => {
  return (
    <label className={className || ""}>
      {title} {!!sebLabel && <span>{sebLabel}</span>}
      {required && <span className="astrick">*</span>}{" "}
      {showInfo && (
        <span>
          {!!tooltipText && (
            <Tooltip id={anchorSelectClass} style={{maxWidth:250,
              backgroundColor:'#eaebec',color: '#000',fontWeight:500,zIndex:9,opacity:1}}>
              {tooltipText}
            </Tooltip>
          )}
          <img
            src={InfoIcon}
            data-tooltip-id={anchorSelectClass}
            width={15}
            alt="label image"
            className="tooltipIconPointer"
          ></img>
        </span>
      )}
    </label>
  );
};

export default Label;
