import { lazy } from "react";
import { Loadable, MinimalLayout } from "components";

const AuthLogin = Loadable(lazy(() => import("pages/auth/login")));
const AuthVerify = Loadable(lazy(() => import("pages/auth/verify")));
const Error404 = Loadable(lazy(() => import("pages/auth/error")));
const PressReleaseWeb = Loadable(lazy(() => import("pages/common/press-release-link")));
const RedirectToDashboard = Loadable(lazy(() => import("pages/auth/redirect-to-dashboard")));
const PressReleasePreview = Loadable(lazy(() => import("pages/common/press-release-preview")));
const Events = Loadable(lazy(() => import("pages/auth/events")));
const EventsTwo = Loadable(lazy(() => import("pages/auth/eventsTwo")));
const SignUp = Loadable(lazy(() => import("pages/auth/sign-up")));

const LoginRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/",
      element: <AuthLogin />,
    },
    {
      path: "login",
      element: <AuthLogin />,
    },
    {
      path: "verify",
      element: <AuthVerify />,
    },
    {
      path: "press-release",
      element: <PressReleaseWeb />,
    },
    {
      path: "redirect-to-dashboard",
      element: <RedirectToDashboard />,
    },
    {
      path: "press-release-preview/:pressReleaseId",
      element: <PressReleasePreview />,
    },
    {
      path: "event/apicon",
      element: <Events />,
    },
    {
      path: "event/globalmeetingofMGB-OAGB2024",
      element: <EventsTwo />,
    },
    {
      path: "signup",
      element: <SignUp />,
    },
    {
      path: "error",
      element: <Error404 />,
    },
    {
      path: "*",
      element: <Error404 />,
    },
  ],
};

export default LoginRoutes;
