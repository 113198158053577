import { burgerMenu } from 'assets';
import { IconProps } from 'types/Others';

const BurgerMenuIcon = ({
  width = 24,
  height = 24
}: IconProps) => {
  return (
      <img
        src={burgerMenu}
        alt="menu"
        width={width}
        height={height}
      />
  )
}

export default BurgerMenuIcon