import ApiUrls from "services/ApiUrl";
import BaseService from "services/Base";
import { Master } from "types/common/master";

export default class ProductsService extends BaseService {

    async getAllProductsServices(param: Master.BodyParamType = {}) {
      try {
        const response = await this.post(`${ApiUrls.productsService}/list`, param);
        return response?.data;
      } catch (error) {
        throw error;
      }
    }
}